/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentTemplate } from '@/types/salesforce-objects.type';
import { useToast } from 'vue-toastification';
import CalloutAppApiService from './callout-app-api.service';
import { useSalesforceStore } from '@/stores/salesforce.store';
import { Vue } from 'vue-class-component';

export default class DocumentTemplateService {
	static toast = useToast();
	static sfStore: ReturnType<typeof useSalesforceStore>;

	/**
	 * Recupere et renvoie les documents template d'un environnement salesforce
	 * @param salesforceInstanceUrl
	 * @param token
	 * @returns Doucment template d'un environment salesforce
	 */
	static async getAllDocumentTemplatesInfo(orgId: string): Promise<DocumentTemplate[]> {
		this.sfStore = useSalesforceStore();
		const documentTemplates: DocumentTemplate[] = [];
		if (this.sfStore.docTemplates && this.sfStore.docTemplates[orgId]) return this.sfStore.docTemplates[orgId];

		try {
			const responseTemplates = await CalloutAppApiService.calloutAsync(
				'get',
				`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/templates`
			);

			const documentTemplateRecords = responseTemplates.records;
			const contentVersionIds = [];

			for (const documentTemplateRecord of documentTemplateRecords) {
				const contentVersionId = documentTemplateRecord.gnx__Salesforce_content_version_id_sent__c;
				if (contentVersionId) contentVersionIds.push(contentVersionId);
			}

			const responseContentVersion = await CalloutAppApiService.calloutAsync(
				'post',
				`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/templates/contentversion`,
				{ contentIds: contentVersionIds }
			);
			if (responseContentVersion.records?.length > 0) {
				const contentVersions: [] = responseContentVersion.records;
				for (const documentTemplateRecord of documentTemplateRecords) {
					if (documentTemplateRecord.gnx__Salesforce_content_version_id_sent__c) {
						const contentVersion: any = contentVersions.find(
							(version: any) =>
								version.id === documentTemplateRecord.gnx__Salesforce_content_version_id_sent__c
						);
						const documentTemplate: DocumentTemplate = {
							lastSendDate: new Date(documentTemplateRecord.gnx__date_of_last_send__c),
							name: documentTemplateRecord.name,
							azureId: documentTemplateRecord.gnx__Template_ID_on_Azure__c,
							fileName: contentVersion?.title ? contentVersion?.title : '',
							extension: contentVersion?.fileExtension,
							size: contentVersion?.contentSize,
							externalId: documentTemplateRecord.gnx__External_ID__c,
							id: documentTemplateRecord.id,
						};
						documentTemplates.push(documentTemplate);
					} else {
						const documentTemplate: DocumentTemplate = {
							name: documentTemplateRecord.name,
							fileName: '',
							externalId: documentTemplateRecord.gnx__External_ID__c,
							id: documentTemplateRecord.id,
						};
						documentTemplates.push(documentTemplate);
					}
				}
			}
			if (this.sfStore.docTemplates == null) {
				this.sfStore.docTemplates = { [orgId]: documentTemplates };
			} else {
				this.sfStore.docTemplates[orgId] = documentTemplates;
			}
			return this.sfStore.docTemplates[orgId];
		} catch (error) {
			console.error('📄 Erreur lors de la requête de recuperation des document templates :');
			console.error(error);
			return documentTemplates;
		}
	}

	/**
	 * Déploiement des document template
	 * @param salesforceInstanceUrl
	 * @param instanceDestinationUrl
	 * @param token
	 * @param targetToken
	 * @param externalIds
	 * @param orgId
	 */
	static async deployDocumentTemplates(
		salesforceInstanceUrl: string,
		instanceDestinationUrl: string,
		externalIds: string[],
		typeOrg: string,
		orgId: string
	) {
		this.sfStore = useSalesforceStore();

		CalloutAppApiService.calloutAsync('post', `${process.env.VUE_APP_APP_API_URI}/api/deployment`, {
			targetInstanceUrl: instanceDestinationUrl,
			instanceUrl: salesforceInstanceUrl,
			externalIds: externalIds,
			typeOrg: typeOrg,
		})
			.then(() => {
				this.toast.success(Vue.prototype.$t('success.deployment'));
			})
			.catch((error) => {
				console.error('📄 Erreur lors du déploiement des modèles :');
				console.error(error);
				this.toast.error(Vue.prototype.$t('error.deployment'));
			})
			.finally(() => {
				this.sfStore.deleteTemplates(orgId);
			});
	}
}
