
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { EditUser, GonexaUser } from '@/types/gonexa-users.type';
import UserService from '@/services/user.service';

interface CreationInputs {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
	roleName: string;
}
@Options({
	props: {
		originalUser: {} as GonexaUser,
		usersList: [] as GonexaUser[],
	},
})
export default class UserEditModal extends Vue {
	toast = useToast();
	isLoading = false;
	inputErrors = {} as CreationInputs; // Objet qui contient les messages erreurs associés à chaque champ de saisie

	originalUser!: GonexaUser;
	usersList!: GonexaUser[];

	editedUser = {} as GonexaUser;
	roleNameField = '';
	showList = false;

	async mounted() {
		this.editedUser = {
			username: this.originalUser.username,
			firstName: this.originalUser.firstName,
			lastName: this.originalUser.lastName,
			email: this.originalUser.email,
			roleName: this.originalUser.roleName,
		} as GonexaUser;

		this.roleNameField = this.editedUser.roleName == 'owner' ? this.$t('users.owner') : this.$t('users.limited');
	}

	selectRole(roleName: string) {
		if (!roleName) {
			this.inputErrors.roleName = this.$t('error.emptyField');
			this.roleNameField = '';
		} else if (roleName == 'owner') {
			this.roleNameField = this.$t('users.owner');
		} else if (roleName == 'user') {
			this.roleNameField = this.$t('users.limited');
		} else {
			this.inputErrors.roleName = this.$t('error.roleNotIdentified');
		}

		this.editedUser.roleName = roleName;
		this.showList = false;
	}

	close() {
		this.editedUser = {} as GonexaUser;
		this.$emit('close');
	}

	action() {
		this.$emit('action', this.originalUser.username, this.editedUser);
		this.close();
	}

	async editExistingUser() {
		if (await this.checkInputErrors()) {
			this.isLoading = true;

			await UserService.updateUser({
				OriginalUsername: this.originalUser.username,
				Email: this.editedUser?.email,
				LastName: this.editedUser?.lastName,
				FirstName: this.editedUser?.firstName,
				Username: this.editedUser?.username,
				RoleName: this.editedUser?.roleName,
			} as EditUser)
				.then((response: string) => {
					switch (response) {
						case 'InvitationMailResent': {
							this.toast.success(Vue.prototype.$t('auth.emails.resent') + this.originalUser?.email);
							break;
						}
						case 'UserModifedMailResent': {
							this.toast.success(Vue.prototype.$t('auth.emails.modifiedResent'));
							this.editedUser.email = this.originalUser.email;
							break;
						}
						case 'UserModified': {
							this.toast.success(Vue.prototype.$t('success.userModified'));
							break;
						}
					}

					this.isLoading = false;
					this.action();
				})
				.catch((error: Error) => {
					switch (error.message) {
						case 'IsNotOwner': {
							this.toast.error(Vue.prototype.$t('error.notOwner'));
							break;
						}
						case 'UserDoesNotExist': {
							this.toast.error(Vue.prototype.$t('error.auth.userDoesNotExist'));
							break;
						}
						case 'UserInTheWrongCompany': {
							this.toast.error(Vue.prototype.$t('error.wrongCompany'));
							break;
						}
						case 'UnknownRole': {
							this.toast.error(Vue.prototype.$t('error.roleNotIdentified'));
							break;
						}
						case 'UsernameAlreadyExists': {
							this.toast.error(Vue.prototype.$t('error.auth.usernameAlreadyExists'));
							break;
						}
						case 'RegistrationNotCompleted': {
							this.toast.error(Vue.prototype.$t('error.auth.userRegistrationNotSucceeded'));
							break;
						}
						case 'MinimumOneOwner': {
							this.toast.error(Vue.prototype.$t('error.minimumOneOwner'));
							break;
						}
						default: {
							this.toast.error(Vue.prototype.$t('error.editUser'));
							break;
						}
					}

					this.isLoading = false;
				});
		}
	}

	/**
	 * Méthode de vérification des données saisies par l'utilisateur avant qu'elles ne soient envoyées à l'API pour inscription
	 * @returns boolean : true si les données sont valides et peuvent être envoyées, fales sinon
	 */
	async checkInputErrors(): Promise<boolean> {
		let validateRegistration = true;

		if (!this.editedUser.firstName) {
			this.inputErrors.firstName = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.editedUser.lastName) {
			this.inputErrors.lastName = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.editedUser.email) {
			this.inputErrors.email = this.$t('error.emptyField');
			validateRegistration = false;
		} else if (!this.editedUser.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi)) {
			this.inputErrors.email = this.$t('error.email.wrongFormat');
			validateRegistration = false;
		}

		if (!this.editedUser.username) {
			this.inputErrors.username = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.editedUser.roleName) {
			this.inputErrors.roleName = this.$t('error.emptyField');
			validateRegistration = false;
		} else if (this.editedUser.roleName != 'owner' && this.editedUser.roleName != 'user') {
			this.inputErrors.roleName = this.$t('error.roleNotIdentified');
			validateRegistration = false;
		}

		return validateRegistration;
	}
}
