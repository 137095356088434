export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "app": {
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
    "deploiement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & Contact"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "synthesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage overview"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users & Profiles"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environments"])},
    "gonexaTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gonexa team"])},
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legals Terms"])},
    "finishConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalize the configuration"])},
    "billingConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices & Consumptions"])}
  },
  "auth": {
    "alreadyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account ?"])},
    "buttons": {
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create my account"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password ?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "validAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate my account"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "goToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the App"])}
    },
    "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been disconnected"])},
    "emails": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your email"])},
      "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox to finalize your registration."])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend an email"])},
      "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verification email has been sent to the following address :"])},
      "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating your email address"])},
      "updatingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email address of this account has been successfully changed."])},
      "consultReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox to complete the reset."])},
      "resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new invitation email has been sent to"])},
      "modifiedResent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A confirmation email has been sent to this user. \nOnce the email address is confirmed, it will be updated."])}
    },
    "inputs": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email address"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])}
    },
    "loginIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to the Gonexa App using your username"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have an account ?"])},
    "password": {
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password check"])},
      "defineFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set password for"])},
      "defineNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your new password"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset my password"])},
      "resetIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email and we will send you a link to reset your password"])}
    },
    "registerIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register on the Gonexa App"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])}
  },
  "billing": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill date"])},
    "lastAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of last invoice"])},
    "lastInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last invoice on"])},
    "late": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late payments"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of invoices"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
    "overallStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall status"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "synthesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage overview"])},
    "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT included"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill to pay"])},
    "upToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up-to-date payments"])},
    "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading the invoice..."])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice is available at the moment"])}
  },
  "consoleError": {
    "emailLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error retrieving email address from Local Storage:"])},
    "salesforceAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the authentication request:"])}
  },
  "consumption": {
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current consumption"])},
    "docTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document templates"])},
    "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 12 months évolution"])},
    "generatedDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents generated"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption limit"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic signatures"])},
    "synthesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage overview"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["templates"])},
    "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
  },
  "contractCard": {
    "clientSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer since"])},
    "gnxSuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Gonexa solutions"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment terms"])},
    "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal date"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My contract"])}
  },
  "deployment": {
    "deployModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy templates"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "lastSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last sent to azure"])},
    "modelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Name"])},
    "modelsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of templates"])},
    "noDestinationSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the destination environment"])},
    "noModelSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one document template"])},
    "noSourceSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the source environment"])},
    "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "sourceEnvironment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source environment"])},
    "targetEnvironment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target environment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploying templates"])},
    "unknownFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No document template on this environment"])}
  },
  "error": {
    "auth": {
      "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an account"])},
      "differentPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must be the same"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to connect"])},
      "passwordStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 12 characters long, one lowercase letter, one uppercase letter, one number and one special character."])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to register"])},
      "registrationNotSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account registration has not been completed"])},
      "unknownUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown username"])},
      "unknownUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown user"])},
      "userDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user does not exist"])},
      "usernameAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username is already used"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password"])},
      "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to update your password"])},
      "initPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create your password"])},
      "userRegistrationNotSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The registration of this account has not been finalized"])},
      "passwordAlreadyInitialized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has already been initialized"])},
      "passwordNotInitialized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has not been initialized yet"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username should have the same format as an email address"])}
    },
    "dbError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred"])},
    "email": {
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to resend email"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to send confirmation email"])},
      "sendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to send confirmation invitation email"])},
      "wrongFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email format"])},
      "wrongAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown email for this user"])},
      "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to check your email address"])},
      "verifyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to check your new email address"])},
      "notVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has not yet been verified"])},
      "notModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It does not appear that the email address for this account needs to be changed"])}
    },
    "emptyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field can not be empty"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create this user"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete this user"])},
    "noConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to access consumption"])},
    "noContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contract"])},
    "noInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice"])},
    "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to redirect"])},
    "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account does not give you access to this feature."])},
    "salesforce": {
      "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
      "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to authenticate with Salesforce"])},
      "inactiveOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization blocked, closed or suspended"])},
      "inactiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive user"])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized access"])},
      "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to find user"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization request limit reached"])}
    },
    "tokenExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has expired"])},
    "companyNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have a verified account to invite users"])},
    "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to deploy templates"])},
    "environment": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete this environment"])},
      "deleteUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This environment does not exist or has already been deleted"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to access environments"])},
      "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to secure this environment"])}
    },
    "getUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to recover users"])},
    "notOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have an owner account to invite users"])},
    "notAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to do this"])},
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to update user information"])},
    "wrongCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user seems to belong to another organization"])},
    "donwloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error downloading invoice"])},
    "accountNotIdentified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error identifying your account"])},
    "companyNotIdentified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error identifying your organization"])},
    "roleNotIdentified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error identifying your account"])},
    "roleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error assigning role to new user"])},
    "minimumOneOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organization mus have at least one owner"])},
    "webdocExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document has expired"])},
    "webdocError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while retrieving the document"])},
    "idNotCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this user's identifiant"])},
    "webdocAlreadySign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already signed the document"])},
    "webdocNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents found"])},
    "webdocSignatureError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while signing the document"])},
    "webdocActionAlreadyRegistred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already performed one of the requested actions"])},
    "webdocNoContentDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No document is linked to the signing procedure"])},
    "downloadWebdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while downloading the file"])},
    "webdocNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot download the document until all signers have signed"])},
    "webdocEveryoneMustSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all signatories have signed yet"])},
    "webdocOops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOPS"])},
    "webdocIssueWhenLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred while loading the document"])},
    "webdocErrorWhileLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading error"])},
    "webdocnoStakeHolders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recipient is associated with this signing procedure"])}
  },
  "errorPage": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "joke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even our technicians are still searching"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to home"])}
  },
  "home": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "connectProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect my production"])},
    "connectSf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to Salesforce"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the support service"])},
    "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy document templates from one environment to another"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification in progress..."])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request refused"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Verification"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated account"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your customer portal"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage the list of environments connected to the App"])},
    "connectMyEnvironments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect my environments"])},
    "connectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your production to access your bills & consumption (after Gonexa has verified your contract)."])},
    "verifInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verification in progress..."])},
    "verificationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our teams carry out a verification"])},
    "connectionProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection authorizes document generation"])}
  },
  "img": {
    "gnxLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo of Gonexa"])}
  },
  "sign": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "consultation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document(s) :"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents:"])},
    "invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are invited to sign this document:"])},
    "invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are invited to sign these documents:"])},
    "notYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not you ?"])},
    "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave the page"])},
    "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECIPIENT :"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients:"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender:"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your document has been signed"])},
    "youSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You sign as"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "documentAvaible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document is now available by clicking on the link below"])},
    "downloadDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download document"])},
    "documentHasBeenSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document has been signed"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "hasBeenSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document sent :"])},
    "documentLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document link has expired"])},
    "linkExpiredText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the sender so they can resend a new link"])},
    "sentMailToExpeditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an email to the sender"])},
    "documentHasBeenValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document has been validated"])},
    "backToDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to document"])},
    "confirmSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the signature of the document?"])},
    "confirmValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm document validation?"])},
    "soonAvaiableDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
    "validationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to finalize the process, you will then be able to download the final document"])},
    "validateValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate the document"])},
    "validateSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm signature"])},
    "documentSoonAvaiable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be able to download the document on this page once all signatories have signed."])},
    "documentSoonAvaiableValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be able to download the document on this page once all the signatories have validated"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "checkTheDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please consult the full document"])},
    "backtoDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to document"])},
    "fieldMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not completed all the required fields"])},
    "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll"])},
    "scrollToBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all the way down to sign"])}
  },
  "success": {
    "registrationCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now ready to use the App! Log in to continue"])},
    "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deleted"])},
    "userInvited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new user has been invited to join the App"])},
    "connectEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully connected environment"])},
    "deleteEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment successfully deleted"])},
    "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates deployed successfully"])},
    "emailResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email returned!"])},
    "secureEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully secured environment"])},
    "userModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User information was successfully updated"])}
  },
  "ticketCard": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log a case"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A question ?"])}
  },
  "universityCard": {
    "gonexaAndMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gonexa & Me"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blaze your trail"])}
  },
  "users": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active users"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
    "confirm1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like"])},
    "confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm deletion"])},
    "confirm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the user?"])},
    "contractContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractual contact"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user"])},
    "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion"])},
    "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users list"])},
    "myInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My information"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user"])},
    "noActiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active users"])},
    "oneActiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active user"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "techContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical contact"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "modification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active users, click on \"New user\" to add new user"])},
    "idCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant copied in the clipboard"])}
  },
  "workspaces": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "activSandboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active sandboxes"])},
    "confirm1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like"])},
    "confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm link removal"])},
    "confirm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["between the sandbox"])},
    "connectProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect my production"])},
    "connectSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect a sandbox"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove link"])},
    "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion"])},
    "myProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My production"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "noActivSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active sandbox"])},
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not connected"])},
    "notSecured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not secure"])},
    "oneActivSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 active sandbox"])},
    "orgId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORG ID"])},
    "orgName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORG NAME"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "sandboxList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of sandboxes"])},
    "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Sandbox, please click on \"Connect Sandbox\""])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not connected"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "connectionToSalesforce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect a Salesforce environment"])},
    "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "configuration1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What"])},
    "configuration2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type of environment"])},
    "configuration3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["would you like to connect to ?"])}
  },
  "webdoc": {
    "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading the document..."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
  }
}