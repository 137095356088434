
import { Options, Vue } from 'vue-class-component';
import { useAuthStore } from '@/stores/authentication.store';

import UserInfosCard from '@/components/user/UserInfosCard.vue';
import ContractInfosCard from '@/components/user/ContractInfosCard.vue';
import GonexaUniversityComponent from '@/components/general/GonexaUniversityComponent.vue';
import ConsumptionCardsGroup from '@/components/consumption/ConsumptionCardsGroup.vue';
import ConfigurationModal from './general/ConfigurationModal.vue';

@Options({
	components: {
		UserInfosCard,
		ContractInfosCard,
		GonexaUniversityComponent,
		ConsumptionCardsGroup,
		ConfigurationModal,
	},
})
export default class HomeView extends Vue {
	authStore = useAuthStore();
	isOwnerFree = false;
	isOwnerVerifInProgress = false;
	isOwnerVerified = false;
	isOwnerBlocked = false;
	isUser = false;
	isConfigurationModelOpen = false;

	mounted(): void {
		const roleName = this.authStore.roleName?.toLowerCase();
		const companyStatus = this.authStore.companyStatus?.toLowerCase();

		if (roleName === 'owner') {
			this.isOwnerFree = companyStatus === 'free';
			this.isOwnerVerifInProgress = companyStatus === 'verificationinprogress';
			this.isOwnerVerified = companyStatus === 'verified';
			this.isOwnerBlocked = companyStatus === 'blocked';
		} else {
			this.isUser = true;
		}
	}

	openSupport() {
		window.open('https://aide.gonexa.fr/s/contactsupport?language=fr', '_blank');
	}

	closeModal() {
		this.isConfigurationModelOpen = false;
	}
	openModal() {
		this.isConfigurationModelOpen = true;
	}
}
