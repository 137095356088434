
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import AuthGonexaService from '@/services/auth-gonexa.service';

export default class EmailSent extends Vue {
	toast = useToast();
	user = {} as { username: string; email: string };

	mounted() {
		try {
			const lsData = localStorage.getItem('APP_U_M');
			this.user = lsData ? JSON.parse(lsData) : '';
		} catch (error) {
			console.error(this.$t('consoleError.emailLS'));
			console.error(error);
		}
	}

	async resendEmail() {
		await AuthGonexaService.sendEmail(
			this.user.username,
			this.user.email,
			this.$route.name == 'email-sent' ? true : false
		)
			.then(() => {
				this.toast.success(this.$t('success.emailResend'));
			})
			.catch((error: Error) => {
				if (error.message == 'WrongEmailForUsername') this.toast.error(this.$t('error.email.wrongAddress'));
				else if (error.message == 'UserDoesNotExist') this.toast.error(this.$t('error.auth.unknownUsername'));
				else this.toast.error(this.$t('error.email.resend'));
			});
	}
}
