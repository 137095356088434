
import { Vue, Options } from 'vue-class-component';
import WebdocService from '@/services/webdoc.service';
import { PicklistModel, StakeHolder, Webdoc, WebdocAction } from '@/types/webdoc.type';
import { useToast } from 'vue-toastification';
import { AxiosError } from 'axios';
import WebdocPopup from './WebdocPopup.vue';
import WebdocError from './WebdocError.vue';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';
import localeFr from 'air-datepicker/locale/fr';

@Options({
	components: {
		WebdocPopup,
		WebdocError,
	},
})
export default class SignView extends Vue {
	docContainer!: HTMLElement | null;
	docControlls!: HTMLElement | null;
	docView!: HTMLElement | null;
	docContent!: HTMLElement | null;

	webdoc: Webdoc | undefined;
	pageNumber = 0;
	currentPage = 0;
	pages: string[] = [];
	pagesRatios: number[] = [];
	actions: WebdocAction[] = [];
	webdocToken = '';

	consultation = true;
	interaction = false;
	signature = false;
	signatureLoaded = true;
	webdocLoaded = false;
	consulted = false;
	docLoading = false;
	expired = false;
	confirmation = false;
	validation = false;
	hasEveryStakeHolderSigned = false;
	menuOpen = false;
	currentLang = false;
	screenState = true;
	allowZoom = false;
	scalingMobile = false;

	lang = 'fr';
	toast = useToast();
	zoom = 1;
	progression = 0;
	error: string | undefined = undefined;
	lastTouchDistance!: number | null;
	datepickers: AirDatepicker<HTMLElement>[] = [];

	async mounted() {
		const screenStateLS = localStorage.getItem('APP_NB');
		if (screenStateLS) this.screenState = screenStateLS == 'true' ? true : false;
		this.manageScreenSize();
		window.addEventListener('resize', () => {
			this.manageScreenSize();
		});

		const lang = localStorage.getItem('APP_LG');
		this.currentLang = lang == 'en' ? true : false;
		this.docContainer = this.$refs.docContainer as HTMLElement;
		this.docControlls = this.$refs.docControlls as HTMLElement;
		this.docContent = this.$refs.docContent as HTMLElement;
		this.docView = this.$refs.docView as HTMLElement;
		const webdocToken = this.$route.params.token.toString();
		this.webdocToken = webdocToken;
		await WebdocService.getWebdoc(webdocToken)
			.then((webdoc) => {
				this.webdoc = webdoc;
				if (this.webdoc.status.toLocaleLowerCase() == 'expired') {
					this.expired = true;
				}
			})
			.catch((error) => {
				console.error(error);
				if (error.response) {
					console.error('Erreur lors de la récuperation de signature ');
					console.error(`Erreur : ${error.response.data}`);
					switch (error.response.data) {
						case 'WebdocExpired': {
							this.expired = true;
							break;
						}
						case 'NoWebdocFound': {
							this.error = 'error.webdocNotFound';
							break;
						}
						case 'NoStakeholdersFound': {
							this.error = 'error.webdocnoStakeHolders';
							break;
						}
						case 'NoContentDocument': {
							this.error = 'error.webdocNoContentDocument';
							break;
						}
						default: {
							this.error = 'error.webdocError';
							break;
						}
					}
				} else {
					this.error = 'error.webdocError';
				}
			});
		this.webdocLoaded = true;
		if (this.webdoc) {
			window.document.title = this.webdoc.name;

			this.setWebdocMode();
			this.hasEveryStakeHolderSigned = this.webdoc.recipients.every(
				(stakeHolder) =>
					!this.hasInteraction(stakeHolder) || stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
			);
			if (
				!this.webdoc.recipients.every(
					(stakeHolder) => stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
				) &&
				!this.expired
			) {
				window.addEventListener('resize', this.adjustDocControlls);
				window.addEventListener('resize', this.adjustDocumentContent);

				this.adjustDocControlls();
				this.$nextTick(async () => {
					await this.loadHtmlDoc();
					this.adjustDocumentContent();
					this.adjustDocControlls();
				});
			}
		}
	}

	public changeLanguage() {
		this.$i18n.locale = this.currentLang ? 'fr' : 'en';
		localStorage.setItem('APP_LG', this.$i18n.locale);
		window.dispatchEvent(new Event('langChanged'));
	}

	hasInteraction(stakeholder: StakeHolder): boolean {
		if (this.webdoc) {
			let hasInteraction = false;
			const signIndex = this.webdoc.recipients.findIndex((elem) => elem.id == stakeholder.id) + 1;

			this.webdoc.interactions.forEach((interaction) => {
				if (interaction.recipient == `s${signIndex}`) {
					hasInteraction = true;
				}
			});
			return hasInteraction;
		} else {
			return false;
		}
	}

	manageScreenSize() {
		if (window.innerWidth <= 1100) {
			if (this.screenState) {
				this.screenState = !this.screenState;
				localStorage.setItem('APP_NB', this.screenState.toString());
			}
			if (window.innerWidth <= 768 && this.allowZoom) {
				this.zoom = (0.47 * window.innerWidth) / 428;
			}
		} else if (window.innerWidth > 1100 && !this.screenState) {
			this.screenState = !this.screenState;
			localStorage.setItem('APP_NB', this.screenState.toString());
			this.zoom = 1;
		}
		this.adjustDocumentContent();
		this.adjustDocControlls();
		this.fixDocumentAlignement();
		this.adjustDocControlls();
	}

	changeLanguageInteraction() {
		Array.from(document.getElementsByClassName('sign-preview__header-text')).forEach((element) => {
			element.textContent = this.$t('sign.preview');
		});
		Array.from(document.getElementsByClassName('sign-preview__date')).forEach((element) => {
			element.textContent = this.$t('sign.the') + ' ' + new Date().toLocaleDateString();
		});
		this.datepickers.forEach((datepicker) => {
			datepicker.update({ locale: this.$i18n.locale == 'fr' ? localeFr : localeEn });
		});
	}

	/**
	 * Contrôle les bouton + et - pour le zoom
	 */
	zoomControl(type: string) {
		if (this.allowZoom) {
			if (type == 'decrease') {
				if (this.zoom > 0.1 && this.zoom - 0.1 > 0) this.zoom = this.zoom - 0.1;
			} else {
				if (this.zoom < 2) this.zoom = this.zoom + 0.1;
			}
			this.adjustDocumentContent();
		}
		this.fixDocumentAlignement();
	}

	handleZoomInput(event: Event) {
		const target = event.target as HTMLInputElement;
		if (target != null) {
			const zoomValue = Number(target.value);
			if (zoomValue != null && zoomValue <= 200 && zoomValue >= 2) {
				this.zoom = zoomValue / 100;
				this.fixDocumentAlignement();
			} else {
				if (zoomValue != null && zoomValue > 200) this.zoom = 2;
				else if (zoomValue != null && zoomValue <= 2) this.zoom = 0.1;

				this.fixDocumentAlignement();
			}
		}
	}

	fixDocumentAlignement(isInNextTick = false) {
		const func = () => {
			const documentContent = document.getElementById('documentContent');
			if (documentContent && documentContent.parentElement) {
				const documentContentRect = documentContent.getBoundingClientRect();
				const documentControllerRect = documentContent.parentElement.getBoundingClientRect();
				const newWidth = (documentControllerRect.width - documentContentRect.width) / 2;
				if (newWidth >= 0) {
					documentContent.style.marginLeft = newWidth + 'px';
					documentContent.style.paddingLeft = '0px';
					documentContent.style.paddingRight = '0px';
					documentContent.style.setProperty('--padding', '0px');
				} else {
					documentContent.style.marginLeft = 0 + 'px';
					documentContent.style.paddingLeft = '2rem';
					documentContent.style.paddingRight = '2rem';
					documentContent.style.setProperty('--padding', '2rem');
				}
				if (this.zoom < 1) {
					documentContent.parentElement.style.setProperty(
						'--contentWidth',
						`${documentControllerRect.height - documentContentRect.height}px`
					);
				} else {
					documentContent.parentElement.style.setProperty('--contentWidth', `0px`);
				}
			}
		};
		if (isInNextTick) {
			func();
		} else {
			this.$nextTick(func);
		}
	}

	nameToIcon(name: string): string {
		let majs = '';
		let count = 0;
		const specialChar = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
		for (let i = 0; i < name.length; i++) {
			if (name[i] == name[i].toUpperCase() && !specialChar.test(name[i])) {
				count++;
				majs += name[i];
				if (count >= 2) break;
			}
		}

		if (count < 2) {
			majs = name.substring(0, 2);
		}

		return majs;
	}

	getRecipients(stakeHolder: StakeHolder[]): StakeHolder[] {
		return stakeHolder.filter((x) => x.type != 'Owner');
	}

	async closeWebdocPopup() {
		this.validation = false;
		this.adjustDocControlls();
		await this.loadHtmlDoc();
		this.adjustDocumentContent();
	}
	setWebdocMode() {
		if (this.webdoc) {
			const signIndex = this.webdoc.recipients.findIndex((elem) => elem.id == this.webdoc?.signatory.id) + 1;
			this.webdoc.interactions.forEach((interaction) => {
				if (interaction.recipient == `s${signIndex}`) {
					this.consultation = false;
					const splittedTag = interaction.tag.replace('{!', '').replace('}', '').split('|');
					if (splittedTag.length == 1) {
						this.signature = true;
					} else if (splittedTag.length > 1) {
						this.interaction = true;
					}
				}
			});
			if (!this.interaction && !this.signature) this.consultation = true;
		}
	}

	openValidationScreen() {
		let hasEveryRequiredInteractionBeenFilled = true;
		this.actions.forEach((action) => {
			if (this.webdoc) {
				const mappedAction = this.webdoc.interactions.find((elem) => {
					if (elem.tag == action.tag) return elem;
				});
				if (
					mappedAction &&
					mappedAction.isOptional &&
					action.value != null &&
					action.value.match(/^ *$/) !== null
				) {
					hasEveryRequiredInteractionBeenFilled = false;
				}
			}
		});
		if (hasEveryRequiredInteractionBeenFilled) this.validation = true;
		else {
			this.toast.error(this.$t('sign.fieldMissing'));
		}
	}

	/**
	 * Charge TOUT les fichiers en html
	 */
	async loadHtmlDoc(index = 0) {
		let updateFunction: (() => void)[] = [];
		if (this.webdoc != undefined) {
			const element = this.webdoc.contentDocuments[index];
			this.docLoading = true;
			let file = await WebdocService.getBlobFromContentId(this.$route.params.token.toString(), element.id);
			this.docLoading = false;
			const htmlContainer = document.createElement('div');
			const containerId = `document-container-${element.id}`; // ID unique pour chaque container
			htmlContainer.id = containerId; // Associer un ID
			htmlContainer.classList.add('document-container');

			//retire les balises qui coupe la balise signature
			if (index == 0) file = file.replaceAll('<span class="stl_06">', '');

			htmlContainer.innerHTML = file;

			const docContainer = document.getElementById('documentContent') as HTMLDivElement;

			//on affiche les pages et ont leur met les ombres
			if (docContainer) {
				docContainer.appendChild(htmlContainer);

				//pour le premier document (tout les autres documents sont des annexes)
				if (index == 0)
					if (htmlContainer) {
						const signIndex =
							this.webdoc.recipients.findIndex((elem) => elem.id == this.webdoc?.signatory.id) + 1;
						const spans = Array.from(htmlContainer.getElementsByTagName('span'));
						const spansLength = spans.length;

						//pour tout les spans
						let tagsBuffer: string[] = [];
						let spansBuffer: HTMLSpanElement[] = [];
						let recordBuffer = false;
						for (let i = 0; i < spansLength; i++) {
							const textContent = spans[i].textContent;

							//Si la balise span a du contenu
							if (textContent) {
								if (!recordBuffer && textContent.replaceAll(' ', '').includes('{')) {
									recordBuffer = true;
								}
								if (recordBuffer && spans[i]) {
									tagsBuffer.push(textContent);
									spansBuffer.push(spans[i]);
								}
								if (recordBuffer && textContent.replaceAll(' ', '').includes('}')) recordBuffer = false;

								const bufferedTag = tagsBuffer.join('').replaceAll(/\s/g, '');
								const isMultipleLineTag = /{!.*?}/g.test(bufferedTag);
								const isOneLineTag = /{!.*?}/g.test(textContent);

								//Si le contenu de la span contient une balise
								if (isOneLineTag || isMultipleLineTag) {
									docContainer.style.display = 'block';
									if (isOneLineTag) {
										let found = spans[i];
										const matches = found.textContent?.match(/{!.*?}/g);

										if (matches) {
											found.innerHTML = found.innerHTML.replace(
												/{!.*?}/g,
												'<span style="color:white">' + '$&' + '</span>'
											); //on met les balise qui correspondent à la regex, en blanc

											matches.forEach((match) => {
												const whiteNode = Array.from(
													htmlContainer.getElementsByTagName('span')
												).find((elem) => {
													const elemMatches = elem.textContent?.match(/{!.*?}/g);
													if (elemMatches) {
														if (
															elemMatches.length == 1 &&
															elemMatches[0] == match &&
															elem.style.color == 'white'
														)
															return elem;
													}
												}); //on récupère la balise en blanc qui correspond à la balise pour récuperer sa position

												const splittedAction = match
													.replace('{', '')
													.replace('}', '')
													.replace('!', '')
													.split('|'); //on recupere chaque partie de la balise

												updateFunction.push(() =>
													this.insertInteractionNode(
														splittedAction,
														signIndex,
														whiteNode,
														found,
														htmlContainer,
														match
													)
												);
											});
										}
									} else {
										let found = spansBuffer[0]; //on met les balise qui correspondent à la regex, en blanc
										for (let j = 0; j < tagsBuffer.length; j++) {
											const spanTag = spansBuffer[j];
											const matches = spanTag.textContent?.match(tagsBuffer[j]);
											if (matches) {
												if (spanTag) {
													const newContent = spansBuffer[j].textContent?.replace(
														matches[0],
														'<span style="color:white">' + '$&' + '</span>'
													);
													if (newContent) spansBuffer[j].innerHTML = newContent;
												}
											}
										}

										const splittedAction = bufferedTag
											.replace('{', '')
											.replace('}', '')
											.replace('!', '')
											.split('|'); //on recupere chaque partie de la balise

										if (found)
											updateFunction.push(() =>
												this.insertInteractionNode(
													splittedAction,
													signIndex,
													undefined,
													found,
													htmlContainer,
													bufferedTag
												)
											);
									}
									spansBuffer = [];
									tagsBuffer = [];
								}
							}
						}
					}

				const elements = docContainer.querySelectorAll('.stl_');
				elements.forEach((elem) => {
					const htmlElem = elem as HTMLElement;
					htmlElem.style.marginBottom = '40px';
					htmlElem.style.boxShadow = '0px 0px 20px rgba(149, 149, 149, 0.2509803922)';
				});
			}
			//quand le document apparait sur les 1/3 de l'ecran on le valide
			const observer = (id: string) => {
				this.pagesRatios.push(0);
				return new IntersectionObserver(
					(entries) => {
						entries.forEach((entry) => {
							const pageIndex = this.pages.indexOf(id);
							if (pageIndex >= 0) {
								this.pagesRatios[pageIndex] = entry.intersectionRatio;
								const consultedPage = this.pagesRatios.indexOf(Math.max(...this.pagesRatios));
								if (consultedPage >= 0) {
									this.currentPage = consultedPage + 1;
								}
							}
						});
					},
					{ threshold: 0.1 }
				);
			};
			const uuid = () => {
				return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
					(+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
				);
			};

			Array.from(htmlContainer.getElementsByClassName('stl_')).forEach((element) => {
				element.id = uuid();
				const elementObserver = observer(element.id);
				elementObserver.observe(element);
				this.pages.push(element.id);
				this.pageNumber++;
			});

			if (index != this.webdoc.contentDocuments.length - 1) {
				this.loadHtmlDoc(index + 1);
			} else {
				this.setConsultedOnScroll();
				this.initWheelZoomControl();
				this.fixDocumentAlignement();
				this.initTouchZoomControl();
				this.allowZoom = true;
				this.$nextTick(() => {
					updateFunction.reverse();
					if (!this.screenState) {
						updateFunction.forEach((func) => func());
						this.manageScreenSize();
						this.adjustDocumentContent();
						this.$nextTick(() => {
							this.fixDocumentAlignement(true);
						});
					} else {
						updateFunction.forEach((func) => func());
					}
				});
			}
		}
	}

	insertInteractionNode(
		splittedAction: string[],
		signIndex: number,
		whiteNode: HTMLSpanElement | undefined,
		found: HTMLSpanElement,
		htmlContainer: HTMLDivElement,
		match: string
	) {
		if (splittedAction.length > 0 && splittedAction[0] == `s${signIndex}` && this.webdoc) {
			let top =
				whiteNode == undefined
					? found.getBoundingClientRect().top - htmlContainer.getBoundingClientRect().top
					: whiteNode.getBoundingClientRect().top - htmlContainer.getBoundingClientRect().top;
			const left =
				whiteNode == undefined
					? found.getBoundingClientRect().left - htmlContainer.getBoundingClientRect().left
					: whiteNode.getBoundingClientRect().left - htmlContainer.getBoundingClientRect().left; //on recupere les coordonées de l'élément à mettre
			let node = '';
			let action: WebdocAction = {
				tag: match,
				value: '',
				type: 'Signature',
			};
			let interactionIndex = -1;
			const mappedAction = this.webdoc.interactions.find((elem, index) => {
				if (elem.tag == match) {
					interactionIndex = index;
					return elem;
				}
			}); //on recupere les interactions qui sont dans salesforce

			if (mappedAction) {
				this.consultation = false; // on définit que ce webdoc n'est pas fait pour etre que consulté
				if (splittedAction.length == 1) {
					//si c'est une signature
					node = this.getSignPreview(
						this.webdoc.signatory.firstName + ' ' + this.webdoc.signatory.lastName,
						mappedAction.width.toString(),
						mappedAction.height.toString(),
						top.toString(),
						left.toString()
					);
					this.signature = true; //on définit que le document doit être signé par la personne
				} else {
					if (mappedAction.type == 'checkbox') mappedAction.height = mappedAction.width; //on définit la height à la même taille que la width
					this.interaction = true; // on définit qu'il y a des interractions
					top =
						top +
						(whiteNode == undefined
							? found.getBoundingClientRect().height / 2 - mappedAction.height / 2
							: whiteNode.getBoundingClientRect().height) /
							2 -
						mappedAction.height / 2;
					switch (mappedAction.type) {
						case 'text': {
							if (mappedAction.isAutofilled) action.value = mappedAction.value ? mappedAction.value : '';

							node = `<div style="position: absolute; margin: 0; z-index:100; padding: 0; padding-left: 5px; width: fit-content; margin:0; top: ${top}px; left: calc( ${left}px + var(--padding))"><input class="interaction-input" id="interaction-${interactionIndex}" type="text" ${
								mappedAction.isAutofilled ? `value="${action.value}"` : ''
							}
							maxlength="${mappedAction.maxLength}"  style=";width: ${mappedAction.width
								.toString()
								.trim()}px;height: ${mappedAction.height
								.toString()
								.trim()}px; outline: none;padding: 0;padding-left: 5px; border: solid 1px ${
								!mappedAction.isOptional ? 'rgb(0,0,0, .1)' : '#E58180'
							}; border-radius: 6px;background: #FCFCFC;"/>
								</div>`;
							action.type = 'String';
							break;
						}
						case 'number': {
							if (mappedAction.isAutofilled) action.value = mappedAction.value ? mappedAction.value : '';

							node = `<div style="position: absolute; margin: 0; z-index:100; padding: 0; width: fit-content; margin:0; top: ${top}px; left: calc( ${left}px + var(--padding))"><input class="interaction-input" id="interaction-${interactionIndex}" type="number" ${
								mappedAction.isAutofilled ? `value="${action.value}"` : ''
							}
							maxlength="${mappedAction.maxLength}"  style=";width: ${mappedAction.width
								.toString()
								.trim()}px;height: ${mappedAction.height
								.toString()
								.trim()}px; outline: none;padding: 0;padding-left: 5px; border: solid 1px ${
								!mappedAction.isOptional ? 'rgb(0,0,0, .1)' : '#E58180'
							}; border-radius: 6px;background: #FCFCFC; -webkit-appearance: textfield; -moz-appearance: textfield;"/>
								</div>`;
							action.type = 'String';
							break;
						}
						case 'checkbox': {
							if (mappedAction.isAutofilled) {
								action.value = mappedAction.value ? mappedAction.value : 'false';
							} else {
								action.value = 'false';
							}

							node = `<div style="position: absolute; margin: 0; z-index:100; padding: 0; width: fit-content; margin:0; top: ${top}px; left: calc( ${left}px + var(--padding)); width: ${mappedAction.width
								.toString()
								.trim()}px;height: ${mappedAction.height
								.toString()
								.trim()}px;"><input class="interaction-input gnx-checkbox-btn" id="interaction-${interactionIndex}" type="checkbox"
								${mappedAction.isAutofilled ? (mappedAction.value == 'true' ? 'checked' : '') : ''} 
								style="margin: 0; outline: none;padding: 0; border: solid 1px ${
									!mappedAction.isOptional ? 'rgb(0,0,0, .1)' : '#E58180'
								}; border-radius: 6px;background: #FCFCFC; width:${mappedAction.width}px; height:${
								mappedAction.height
							}px;"/>
								<label class="gnx-checkbox-txt interaction" for="interaction-${interactionIndex}" style="position:static; width:${
								mappedAction.width
							}px; height:${mappedAction.height}px;"></label>
								</div>`;
							action.type = 'checkbox';
							break;
						}
						case 'picklist': {
							if (mappedAction.value) {
								const picklistModel = JSON.parse(mappedAction.value) as PicklistModel;
								if (mappedAction.isAutofilled && picklistModel.oldValue != null)
									action.value = picklistModel.oldValue ? picklistModel.oldValue : '';
								let picklistOptions = '';
								picklistOptions += `<option ${!mappedAction.isAutofilled ? 'selected' : ''} ${
									mappedAction.isOptional ? 'disabled' : ''
								}></option>`;
								picklistModel.picklistValues.forEach((value, index) => {
									picklistOptions += `<option value="${value}" ${
										mappedAction.isAutofilled &&
										picklistModel.oldValue != null &&
										picklistModel.oldValue === value
											? 'selected'
											: ''
									} >${picklistModel.picklistLabels[index]}</option>`;
								});

								node = `<div style="position: absolute; margin: 0; z-index:100; padding: 0; width: fit-content; margin:0; top: ${top}px; left: calc( ${left}px + var(--padding))">
										<select class="interaction-input" id="interaction-${interactionIndex}" style="width: ${mappedAction.width
									.toString()
									.trim()}px;height: ${mappedAction.height
									.toString()
									.trim()}px; outline: none;padding: 0; padding-left: 5px; border: solid 1px ${
									!mappedAction.isOptional ? 'rgb(0,0,0, .1)' : '#E58180'
								}; border-radius: 6px;background: #FCFCFC;">
									${picklistOptions}
								</select>
								</div>`;
								action.type = 'String';
							}

							break;
						}
						case 'date': {
							if (mappedAction.isAutofilled) action.value = mappedAction.value ? mappedAction.value : '';
							node = `<div style="position: absolute; margin: 0; z-index:100; padding: 0; width: fit-content; margin:0; top: ${top}px; left: calc( ${left}px + var(--padding))">
							<input class="interaction-input" id="interaction-${interactionIndex}" type="text" ${
								mappedAction.isAutofilled
									? `value="${
											mappedAction.value != null && mappedAction.value != ''
												? mappedAction.value
												: ''
									  }"`
									: ''
							}
							maxlength="${mappedAction.maxLength}"  style="width: ${mappedAction.width
								.toString()
								.trim()}px;height: ${mappedAction.height
								.toString()
								.trim()}px; outline: none;padding: 0; padding-left: 5px; border: solid 1px ${
								!mappedAction.isOptional ? 'rgb(0,0,0, .1)' : '#E58180'
							}; border-radius: 6px;background: #FCFCFC; background-image: url(${require('@/assets/svg/calendar_grey.svg')}); 
							background-size: 20px; background-position: right; background-repeat: no-repeat;"/>
								</div>`;
							action.type = 'String';
							break;
						}
						default: {
							console.error('error with input type ' + mappedAction.type);
						}
					}
				}
				this.actions.push(action);
				htmlContainer.insertAdjacentHTML('afterbegin', node);
				if (interactionIndex >= 0 && splittedAction.length > 1) {
					const interaction = document.getElementById(`interaction-${interactionIndex}`);
					if (interaction) {
						const index = this.actions.length - 1;
						if (mappedAction.type == 'checkbox' || mappedAction.type == 'picklist') {
							interaction.addEventListener('change', (ev) => {
								if (mappedAction.type == 'checkbox') {
									const interactionInput = ev.target as HTMLInputElement;
									this.actions[index].value = interactionInput.checked.toString();
								} else {
									const interactionInput = ev.target as HTMLSelectElement;
									this.actions[index].value = interactionInput.value.toString();
								}
							});
						} else if (mappedAction.type == 'date') {
							const selectedDate = new Date(action.value);
							const changeDateValue = (value: string) => {
								this.actions[index].value = value;
							};
							const airpicker = new AirDatepicker(interaction, {
								multipleDates: false,
								selectedDates:
									mappedAction.isAutofilled && action.value != '' && action.value != null
										? [selectedDate]
										: false,
								dateFormat: 'yyyy-MM-dd',
								locale: this.$i18n.locale == 'fr' ? localeFr : localeEn,
								onSelect({ date, formattedDate, datepicker }) {
									if (typeof formattedDate === 'string') changeDateValue(formattedDate);
								},
							});
							this.datepickers.push(airpicker);
						} else {
							interaction.addEventListener('keyup', (ev) => {
								const interactionInput = ev.target as HTMLInputElement;
								this.actions[index].value = interactionInput.value.toString();
							});
							if (mappedAction.type == 'number')
								interaction.addEventListener('beforeinput', (ev) => {
									const data = ev.data;
									if (data) {
										const isAllow = /\d|\./.test(data);
										if (!isAllow) {
											ev.preventDefault();
										}
									}
								});
						}
					}
				}
			}
		}
	}

	initTouchZoomControl() {
		const docController = document.getElementById('documentController') as HTMLDivElement;
		if (docController) {
			docController.addEventListener('touchstart', (e) => {
				if (e.touches.length === 2) {
					e.preventDefault();
					this.scalingMobile = true;
					this.lastTouchDistance = this.getDistance(e.touches[0], e.touches[1]);
				}
			});
			docController.addEventListener('touchmove', (e) => {
				if (e.touches.length === 2 && this.lastTouchDistance !== null) {
					e.preventDefault();
					const currentTouchDistance = this.getDistance(e.touches[0], e.touches[1]);
					const distanceChange = currentTouchDistance - this.lastTouchDistance;

					this.zoom += distanceChange * 0.005;
					this.zoom = Math.max(0.1, Math.min(this.zoom, 2));
					this.fixDocumentAlignement();

					this.lastTouchDistance = currentTouchDistance;
				}
			});
			docController.addEventListener('touchend', (e) => {
				if (e.touches.length < 2) {
					this.lastTouchDistance = null;
				}
			});
		}
	}

	initWheelZoomControl() {
		const docController = document.getElementById('documentController') as HTMLDivElement;
		if (docController)
			docController.addEventListener(
				'wheel',
				(event) => {
					if (event.ctrlKey && this.allowZoom) {
						event.preventDefault();
						const delta = Math.sign(event.deltaY);
						if (delta > 0) {
							this.zoomControl('decrease');
						} else {
							this.zoomControl('increase');
						}
					}
				},
				{ passive: false }
			);
	}

	getDistance(touch1: Touch, touch2: Touch): number {
		const dx = touch1.clientX - touch2.clientX;
		const dy = touch1.clientY - touch2.clientY;
		return Math.sqrt(dx * dx + dy * dy);
	}

	setConsultedOnScroll() {
		const documentView = document.getElementById('docView');
		if (documentView) {
			documentView.addEventListener('scroll', () => {
				const scrollStage = documentView.scrollHeight - documentView.offsetHeight;
				const percetenage = (100 * documentView.scrollTop) / scrollStage;

				if (this.progression < percetenage) {
					this.progression = percetenage;

					Array.from(document.getElementsByClassName('sign-btn')).forEach((element) => {
						(element as HTMLElement).style.setProperty('--progression', `${this.progression}%`);
					});
				}

				if (documentView.scrollTop == scrollStage || documentView.scrollTop + 2 >= scrollStage) {
					this.consulted = true;
				}
			});
		}
	}

	/**
	 * Ajuste la taille des contrôles (zoom etc...) du doc
	 */
	adjustDocControlls() {
		const docControlls = document.getElementById('documentControlls');
		const docView = document.getElementById('docView');
		const docContainer = document.getElementById('docContainer');
		let scrollPopup = document.getElementById('scroll-popup');
		if (docView && docControlls) {
			docControlls.style.width = (docView.offsetWidth - 20).toString() + 'px';
			if (scrollPopup) {
				if (docContainer) {
					scrollPopup.style.width = docContainer.getBoundingClientRect().width + 'px';
				} else {
					scrollPopup.style.width = docView.getBoundingClientRect().width + 'px';
				}
			}
		} else {
			if (scrollPopup) {
				if (docContainer) {
					scrollPopup.style.width = docContainer.getBoundingClientRect().width + 'px';
				}
			} else {
				this.$nextTick(() => {
					const scrollPopup = document.getElementById('scroll-popup');
					const docContainer = document.getElementById('docContainer');
					if (scrollPopup && docContainer)
						scrollPopup.style.width = docContainer.getBoundingClientRect().width + 'px';
				});
			}
		}
	}

	/**
	 * ajuste la taille du document pour qu'il soit visible correctement
	 */
	adjustDocumentContent() {
		if (this.docContent && this.docView) {
			const top = (this.docContent.offsetHeight * this.zoom - this.docView.offsetHeight + 30) / 4;

			this.docContent.style.top = top + 'px';
		}
	}

	mailToOwner() {
		if (this.webdoc) {
			window.location.href = `mailto:${
				this.webdoc.recipients.filter((recipient) => recipient.type.toLocaleLowerCase() == 'owner')[0].email
			}`;
		}
	}

	async setDocumentSigned() {
		const webdocToken = this.$route.params.token.toString();
		this.signatureLoaded = false;
		let hasEveryRequiredInteractionBeenFilled = true;
		this.actions.forEach((action) => {
			if (this.webdoc) {
				const mappedAction = this.webdoc.interactions.find((elem) => {
					if (elem.tag == action.tag) return elem;
				});
				if (
					mappedAction &&
					mappedAction.isOptional &&
					action.value != null &&
					action.value.match(/^ *$/) !== null
				) {
					hasEveryRequiredInteractionBeenFilled = false;
					this.toast.error(this.$t('sign.fieldMissing'));
					this.signatureLoaded = true;
					this.validation = false;
					this.actions = [];
					this.zoom = 1;
					this.loadHtmlDoc();
				}
			}
		});
		if (hasEveryRequiredInteractionBeenFilled)
			await WebdocService.setWebdocSign(webdocToken, this.actions)
				.then(() => {
					if (this.webdoc) {
						this.webdoc.signatory.status = 'signed';
						this.webdoc.recipients.forEach((recipient) => {
							if (recipient.email == this.webdoc?.signatory.email)
								recipient.status = this.webdoc.signatory.status;
						});
						this.hasEveryStakeHolderSigned = this.webdoc.recipients.every(
							(stakeHolder) => stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
						);
					}
				})
				.catch((error: AxiosError) => {
					if (error.response)
						switch (error.response.data) {
							case 'AlreadySign': {
								this.toast.error(this.$t('error.webdocAlreadySign'));
								break;
							}
							case 'ActionAlreadyRegistred': {
								this.toast.error(this.$t('error.webdocActionAlreadyRegistred'));
								break;
							}
							case 'WebdocExpired': {
								this.toast.error(this.$t('error.webdocExpired'));
								break;
							}
							case 'NoStakeholdersFound': {
								this.toast.error(this.$t('error.webdocExpired'));
								break;
							}
							case 'NoWebdocFound': {
								this.toast.error(this.$t('error.webdocNotFound'));
								break;
							}
							default: {
								this.toast.error(this.$t('error.webdocSignatureError'));
								break;
							}
						}
				})
				.finally(() => {
					this.signatureLoaded = true;
				});
	}

	getSignPreview(name: string, width: string, height: string, top: string, left: string) {
		const htmlSignPreview = `<div class="sign-preview" style="clear: both;margin: 0; width: ${width.trim()}px; height: ${height.trim()}px; position: absolute; top: ${top}px; left: calc( ${left}px + var(--padding))">
		<div
			class="sign-preview__content"
			style="
				float: left;
				width: ${width.trim()}px;
				height: ${height.trim()}px;
				border: 1px solid #0571BC;
				border-radius: 8px;
				position: relative;
				z-index:100;
			"
		>
			<div
				class="sign-preview__header-text"
				style="
					background: #0571BC;
					color: white;
					border-radius: 10px;
					text-align: center;
					padding: 5px 10px;
					width: fit-content;
					font-family: 'Montserrat', 'Roboto', 'Helvetica', 'sans-serif';
					font-weight: 500;
					position: absolute;
					transform: translate(-50%, -50%);
					z-index: 10;
					left: 50%;
					font-size: 9px;
				"
			>
				${this.$t('sign.preview')}
			</div>
			<div
				class="sign-preview__body"
				style="margin-top: 11px; text-align: center; position: relative; height: ${
					parseInt(height.trim()) - 11
				}px; display: flex; flex-direction: column; justify-content: center;"
			>
				<p class="sign-preview__name" style="font-family: Kalam, cursive; font-size: ${
					Number(width) * ((18 * 0.00566) / name.length)
				}em">${name}</p>
				<p
					class="sign-preview__date"
					style="
						font-size: 8px;
						font-family: 'Montserrat', 'Roboto', 'Helvetica', 'sans-serif';
						color: #616161;
					"
				>
					${this.$t('sign.the') + ' ' + new Date().toLocaleDateString()}
				</p>
			</div>
		</div>
	</div>`;
		return htmlSignPreview;
	}

	/**
	 * Fait télécharger la facture en PDF
	 * @param facture La facture associé à un contrat
	 */
	async downloadWebdocPDF() {
		// if (
		// 	!this.consultation &&
		// 	!this.webdoc?.recipients.every(
		// 		(stakeHolder) =>
		// 			!this.hasInteraction(stakeHolder) || stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
		// 	)
		// ) {
		// 	console.log("📑 Erreur de téléchargement d'un webdoc : Tous les signataires n'ont pas signé");
		// 	console.log('Consultation : ' + this.consultation);
		// 	console.log('Webdoc : ' + this.webdoc?.recipients);
		// 	this.toast.error(this.$t('error.webdocNotDone'));
		// 	return;
		// } else {
		this.toast.info(this.$t('webdoc.downloading'));
		await WebdocService.getWebdocFile(this.webdocToken)
			.then((versionData) => {
				const fileURL = URL.createObjectURL(versionData);
				const link = document.createElement('a');
				link.href = fileURL;
				link.download = this.webdoc?.contentDocuments[0].title + '.pdf';
				link.click();
			})
			.catch((error) => {
				switch (error.response.data) {
					case 'NotSigned': {
						this.toast.error(this.$t('error.webdocEveryoneMustSigned'));
						break;
					}
					default: {
						this.toast.error(this.$t('error.downloadWebdoc'));
						console.error("📑 Erreur de téléchargement d'un webdoc");
						console.error(error);
						break;
					}
				}

				throw new Error();
			});
		//}
	}
}
