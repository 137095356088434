
import { Vue } from 'vue-class-component';

export default class LangChoice extends Vue {
	public currentLang = false;

	mounted() {
		// Valeur par défaut de la langue
		const lang = localStorage.getItem('APP_LG');
		this.currentLang = lang == 'en' ? true : false;
	}

	public changeLanguage() {
		this.$i18n.locale = this.currentLang ? 'fr' : 'en';
		localStorage.setItem('APP_LG', this.$i18n.locale);
		window.dispatchEvent(new Event('langChanged'));
	}
}
