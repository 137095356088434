
import { Options, Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import TicketComponent from '@/components/general/TicketComponent.vue';
import BillingCardsGroup from '@/components/billing/BillingCardsGroup.vue';

import OrgContractService from '@/services/org-contract.service';
import { Invoice } from '@/types/salesforce-objects.type';

@Options({
	components: {
		TicketComponent,
		BillingCardsGroup,
	},
})
export default class BillingView extends Vue {
	toast = useToast();
	invoices: Invoice[] | undefined = undefined;
	infoLoaded = false;
	isNbSorted = 1;
	isDateSorted = 0;

	async mounted() {
		try {
			this.invoices = (await OrgContractService.getAllInvoices())?.sort((x, y) =>
				x.gnx__Invoice_number__c.localeCompare(y.gnx__Invoice_number__c)
			);
			this.infoLoaded = true;
		} catch {
			this.toast.error(this.$t('error.noInvoice'));
			this.infoLoaded = true;
		}
	}

	// TO FIX : Téléchargement des factures
	/**
	 * Fait télécharger la facture en PDF
	 * @param facture La facture associé à un contrat
	 */
	/* async downloadInvoicePDF(invoice: Invoice) {
		this.toast.info(this.$t('billing.downloading'));
		const versionData = await OrgContractService.getInvoiceVersionData(invoice.id);
		const fileURL = URL.createObjectURL(versionData);
		const link = document.createElement('a');
		link.href = fileURL;
		link.download = 'F-' + invoice.date_de_facture_Formule__c + '-' + invoice.name.replace('INV-', '') + '.pdf';
		link.click();
	} */

	getFormattedDate(dateString: string) {
		try {
			if (dateString != undefined) {
				// Si c'est un format DATETIME, je ne garde que la partie DATE
				if (dateString.includes('T')) dateString = dateString.slice(0, 10);
				const dateParts: any = dateString.split('-');
				const date = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

				// Récupère le jour, le mois et l'année de la date
				const day = date.getDate().toString().padStart(2, '0');
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const year = date.getFullYear();

				if (this.$i18n.locale == 'fr') {
					// Formate la date en DD/MM/YYYY si l'App est en français
					return `${day}/${month}/${year}`;
				} else {
					// Formate la date en DD/MM/YYYY sinon
					return `${day}/${month}/${year}`;
				}
			}
		} catch (error) {
			console.error(error);
		}
	}

	compareDates(dueDateStr: string): number {
		if (dueDateStr.includes('T')) dueDateStr = dueDateStr.slice(0, 10);
		const dueDateParts: any = dueDateStr.split('-');
		let dueDate = new Date(+dueDateParts[0], dueDateParts[1] - 1, +dueDateParts[2]);
		dueDate.setHours(0, 0, 0, 0);

		let currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);

		if (dueDate < currentDate) return -1;
		else return 0;
	}

	sortTableColumn() {
		// Si 0, alors pas trié, donc on tri en croissant
		// Si 1, trié en croissants, donc on trie en décroissant
		// Si -1, trié en décroissant, dont on trie en croissant

		if (this.invoices != undefined) {
			let newSortedList = [];

			newSortedList =
				this.isNbSorted == 1
					? this.invoices
							.sort((x, y) => x.gnx__Invoice_number__c.localeCompare(y.gnx__Invoice_number__c))
							.reverse()
					: this.invoices.sort((x, y) => x.gnx__Invoice_number__c.localeCompare(y.gnx__Invoice_number__c));

			// Suite au tri, on met à jour la variable pour qu'elle soit cohérente
			this.isNbSorted = this.isNbSorted == 1 ? -1 : 1;

			this.invoices = newSortedList;
		}
	}

	sortTableColumnDate() {
		// Si 0, alors pas trié, donc on tri en croissant
		// Si 1, trié en croissants, donc on trie en décroissant
		// Si -1, trié en décroissant, dont on trie en croissant

		if (this.invoices != undefined) {
			let newSortedList = [];

			newSortedList =
				this.isDateSorted == 1
					? this.invoices.sort(
							(x, y) =>
								this.stringToDate(y.date_de_facture_Formule__c).getTime() -
								this.stringToDate(x.date_de_facture_Formule__c).getTime()
					  )
					: this.invoices
							.sort(
								(x, y) =>
									this.stringToDate(y.date_de_facture_Formule__c).getTime() -
									this.stringToDate(x.date_de_facture_Formule__c).getTime()
							)
							.reverse();

			// Suite au tri, on met à jour la variable pour qu'elle soit cohérente
			this.isDateSorted = this.isDateSorted == 1 ? -1 : 1;

			this.invoices = newSortedList;
		}
	}

	stringToDate(dateString: string): Date {
		return new Date(dateString);
	}
}
