/* eslint-disable @typescript-eslint/no-explicit-any */
import { useToast } from 'vue-toastification';
import { Consumption, Contract, Invoice } from '@/types/salesforce-objects.type';
import CalloutAppApiService from './callout-app-api.service';
import { useSalesforceStore } from '@/stores/salesforce.store';
import OrgInformationsService from './org-informations.service';

/**
 * Gestion du contrat (recuperation du contrat en cours et gestion de la consommation)
 */
export default class OrgContractService {
	static toast = useToast();
	static sfStore: ReturnType<typeof useSalesforceStore>;

	/**
	 * Recupère le contrat Gonexa en cours de l'entreprise
	 * @param orgId : Id de l'Org de Prod de l'entreprise
	 * @returns Données Salesforce du contrat en cours
	 */
	static async getCurrentContract(): Promise<Contract> {
		this.sfStore = useSalesforceStore();
		if (this.sfStore.currentContract) return this.sfStore.currentContract;
		const orgId = await OrgInformationsService.getOrgId();

		return await CalloutAppApiService.calloutAsync(
			'get',
			`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/contract/current`,
			''
		)
			.then((result) => {
				this.sfStore.currentContract = result.records[0];
				return result.records[0];
			})
			.catch((error) => {
				console.error('📑 Erreur de récupération du contrat courant');
				console.error(error);

				throw new Error();
			});
	}

	/**
	 * Retourne la liste de toutes les factures d'une Org
	 * @param orgId Id de l'Org Salesforce
	 * @param contractId Id du contrat
	 * @returns Liste de toutes les factures liées au contrat en cours
	 */
	static async getAllInvoices(): Promise<Invoice[]> {
		this.sfStore = useSalesforceStore();
		if (this.sfStore.invoices) return this.sfStore.invoices;
		const orgId = await OrgInformationsService.getOrgId();

		return await CalloutAppApiService.calloutAsync(
			'get',
			`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/invoices`,
			''
		)
			.then((result) => {
				this.sfStore.invoices = result.records;
				return result.records;
			})
			.catch((error) => {
				console.error('📑 Erreur de récupération de toutes les factures du compte');
				console.error(error);

				throw new Error();
			});
	}

	/**
	 * Recupere toute les objets Consumptions__c pour le contrat précisé en params
	 * @param contractId Id du contrat
	 * @param orgId Id de l'organisation salesforce
	 * @returns Consommation
	 */
	static async getConsumptions(contractId: string): Promise<Consumption[] | undefined> {
		this.sfStore = useSalesforceStore();
		if (this.sfStore.consumptions) return this.sfStore.consumptions;
		const orgId = await OrgInformationsService.getOrgId();

		return await CalloutAppApiService.calloutAsync(
			'get',
			`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/contract/${contractId}/consumptions`,
			''
		)
			.then((result) => {
				this.sfStore.consumptions = result.records;
				return result.records;
			})
			.catch((error) => {
				console.error('📑 Erreur de récupération de la consommation');
				console.error(error);

				return undefined;
			});
	}

	// TO FIX : Téléchargement des factures

	/**
	 * Retourne le Version Data d'une facture
	 * @param orgId Id de l'Org Salesforce
	 * @param invoiceId Id de la facture
	 * @returns Version Data
	 */
	/*
	static async getInvoiceVersionData(invoiceId: string): Promise<Blob> {
		const orgId = await OrgInformationsService.getOrgId();
		return await CalloutAppApiService.calloutAsync(
			'get',
			`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/invoice/${invoiceId}/download`,
			'',
			'blob'
		)
			.then((result) => {
				return result;
			})
			.catch((error) => {
				console.error("📑 Erreur de téléchargement d'une facture");
				console.error(error);
				this.toast.error(Vue.prototype.$t('error.donwloadInvoice'));
				throw new Error();
			});
	}
	*/
}
