
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { GonexaUser } from '@/types/gonexa-users.type';
import UserService from '@/services/user.service';

interface CreationInputs {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
	roleName: string;
}

export default class UserCreateModal extends Vue {
	toast = useToast();
	isLoading = false;

	showList = false;
	newUser = {} as GonexaUser;
	roleNameField = this.$t('users.limited');
	inputErrors = {} as CreationInputs; // Objet qui contient les messages erreurs associés à chaque champ de saisie

	mounted(): void {
		this.newUser.roleName = 'user';
	}

	selectRole(roleName: string) {
		if (!roleName) {
			this.inputErrors.roleName = this.$t('error.emptyField');
			this.roleNameField = '';
		} else if (roleName == 'owner') {
			this.roleNameField = this.$t('users.owner');
		} else if (roleName == 'user') {
			this.roleNameField = this.$t('users.limited');
		} else {
			this.inputErrors.roleName = this.$t('error.roleNotIdentified');
		}

		this.newUser.roleName = roleName;
		this.showList = false;
	}

	action(user: GonexaUser) {
		this.$emit('action', user);
		this.close();
	}

	close() {
		this.newUser = {} as GonexaUser;
		this.$emit('close');
	}

	async createNewUser() {
		if (this.checkInputErrors()) {
			this.isLoading = true;

			UserService.inviteNewUser(this.newUser)
				.then(() => {
					this.toast.success(this.$t('success.userInvited'));
					this.isLoading = false;
					this.action(this.newUser);
					this.close();
				})
				.catch((error: Error) => {
					switch (error.message) {
						case 'OwnerDoesNotExist': {
							this.toast.error(this.$t('error.accountNotIdentified'));
							break;
						}
						case 'CompanyDoesNotExist':
							this.toast.error(this.$t('error.companyNotIdentified'));
							break;
						case 'UnknownRole':
							this.toast.error(this.$t('error.roleNotIdentified'));
							break;
						case 'IsNotOwner': {
							this.toast.error(this.$t('error.notAuthorized'));
							break;
						}
						case 'UsernameAlreadyExists': {
							this.toast.error(this.$t('error.auth.usernameAlreadyExists'));
							break;
						}
						case 'RoleError': {
							this.toast.error(this.$t('error.roleNewUser'));
							break;
						}
						case 'MailTokenError' || 'SendingMailError': {
							this.toast.error(this.$t('error.email.sendInvitation'));
							break;
						}
						default: {
							this.toast.error(this.$t('error.newUser'));
							break;
						}
					}

					this.isLoading = false;
				});
		}
	}

	/**
	 * Méthode de vérification des données saisies par l'utilisateur avant qu'elles ne soient envoyées à l'API pour inscription
	 * @returns boolean : true si les données sont valides et peuvent être envoyées, fales sinon
	 */
	checkInputErrors(): boolean {
		let validateRegistration = true;

		if (!this.newUser.firstName) {
			this.inputErrors.firstName = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.lastName) {
			this.inputErrors.lastName = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.email) {
			this.inputErrors.email = this.$t('error.emptyField');
			validateRegistration = false;
		} else if (!this.newUser.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi)) {
			this.inputErrors.email = this.$t('error.email.wrongFormat');
			validateRegistration = false;
		}

		if (!this.newUser.username) {
			this.inputErrors.username = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.roleName) {
			this.inputErrors.roleName = this.$t('error.emptyField');
			validateRegistration = false;
		} else if (this.newUser.roleName != 'owner' && this.newUser.roleName != 'user') {
			this.inputErrors.roleName = this.$t('error.roleNotIdentified');
			validateRegistration = false;
		}

		return validateRegistration;
	}
}
