/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { ResponseType } from 'axios';
import { useAuthStore } from '../stores/authentication.store';

export default class CalloutAppApiService {
	static authStore: ReturnType<typeof useAuthStore>;

	static async calloutAsync(
		method: string,
		url: string,
		data: any = '',
		responseType: ResponseType = 'json'
	): Promise<any> {
		//  Récupération du UserToken
		const localUserToken = localStorage.getItem('APP_U');
		if (!localUserToken) throw new Error('NoToken');

		// Exécution de la requête API
		try {
			const firstRequestResponse = await axios.request({
				method: method,
				url: url,
				data: data,
				headers: { Authorization: `Bearer ${localUserToken}` },
				responseType: responseType,
			});

			// Si l'appel est réussi, on retourne la réponse
			if (firstRequestResponse.status >= 200 && firstRequestResponse.status <= 202) {
				return firstRequestResponse.data;
			}
		} catch (firstError: any) {
			// Sinon, on traite l'erreur reçue

			// Si l'erreur est liée au toke, on essaye de le renouveler
			if (firstError.response?.status === 401 || firstError.response?.data === 'ExpiredUserAccessToken') {
				// Renouvellement du UserToken
				const newToken = await this.renewUserToken(localUserToken);

				// Mise à jour dans le AuthStore et le LocalStorage
				this.authStore.updateUserToken(newToken);
				localStorage.setItem('APP_U', newToken);

				// Ré-exécution de la requête API avec le nouveau Token
				try {
					const secondRequest = await axios({
						method: method,
						url: url,
						data: data,
						headers: { Authorization: `Bearer ${newToken}` },
					});

					return secondRequest.data;
				} catch (secondError: any) {
					throw new Error(secondError);
				}
			} else {
				throw new Error(firstError);
			}
		}

		/*
		// Si l'appel est réussi, on retourne la réponse
		if (firstRequestResponse.status >= 200 && firstRequestResponse.status <= 202) {
			return firstRequestResponse.data;
		} else {
			// Sinon, si l'erreur
			if (firstRequestResponse.status === 401 || firstRequestResponse.data === 'ExpiredUserAccessToken') {
				const newToken = await this.renewUserToken(localUserToken);
				this.authStore.updateUserToken(newToken);
				localStorage.setItem('APP_U', newToken);
				const secondRequest = await axios({
					method: method,
					url: url,
					data: data,
					headers: { Authorization: `Bearer ${newToken}` },
				});
				return secondRequest.data;
			} else {
				throw new Error(firstRequestResponse.data);
			}
		} */
	}

	// TODO: A tester
	static async renewUserToken(expiredToken: string): Promise<string> {
		const resultRequest = await axios.post(`${process.env.VUE_APP_APP_API_URI}/api/userToken/RenewUserToken`, {
			headers: { Authorization: `Bearer ${expiredToken}` },
		});

		if (resultRequest.status == 200) {
			return resultRequest.data;
		}

		console.error("🔑 Erreur dans le renouvellement de l'Access Token");
		console.error(resultRequest.data);
		throw new Error('UnableToRenew');
	}
}
