
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import AuthGonexaService from '@/services/auth-gonexa.service';
import { RegisteredUserData } from '@/types/gonexa-auth.type';

interface PasswordInputs {
	pwd: string;
	pwdCheck: string;
}

export default class UpdatePasswordForm extends Vue {
	toast = useToast();

	verifiedUsername = '';
	verifiedEmail = '';
	emailToken = '';

	password = '';
	passwordCheck = '';
	seePassword = false;
	seePasswordCheck = false;
	pwdErrors = {} as PasswordInputs;

	buttonLoading = false;

	async mounted() {
		this.emailToken = this.$route.params.emailToken.toString();

		AuthGonexaService.verifyAccount(this.emailToken, this.$route.name == 'initialize-password' ? true : false)
			.then((response: RegisteredUserData) => {
				this.verifiedUsername = response.username;
				this.verifiedEmail = response.email;
			})
			.catch((error: Error) => {
				switch (error.message) {
					case 'UserDoesNotExist': {
						this.toast.error(this.$t('error.auth.unknownUser'));
						break;
					}
					case 'RegistrationAlreadyCompleted': {
						this.toast.error(this.$t('error.auth.passwordAlreadyInitialized'));
						break;
					}
					case 'RegistrationNotCompleted': {
						this.toast.error(this.$t('error.auth.passwordNotInitialized'));
						break;
					}
					default: {
						this.toast.error(this.$t('error.email.verify'));
						break;
					}
				}
				this.$router.push('/login');
			});
	}

	async createPassword() {
		if (this.checkPasswordValidity()) {
			this.buttonLoading = true;
			const encryptedPassword = btoa(this.password);

			AuthGonexaService.createPassword(encryptedPassword, this.verifiedUsername, this.emailToken)
				.then(() => {
					this.buttonLoading = false;
					this.toast.success(this.$t('success.registrationCompleted'));
					this.$router.push({ name: 'login' });
				})
				.catch((error: Error) => {
					if (error.message == 'PasswordAlreadyInitialized')
						this.toast.error(this.$t('error.auth.passwordAlreadyInitialized'));
					else if (error.message == 'EmailNotVerified') this.toast.error(this.$t('error.email.notVerified'));
					else this.toast.error(this.$t('error.auth.initPassword'));

					this.buttonLoading = false;
				});
		}
	}

	async updatePassword() {
		if (this.checkPasswordValidity()) {
			this.buttonLoading = true;
			const encryptedPassword = btoa(this.password);

			AuthGonexaService.updatePassword(encryptedPassword, this.verifiedUsername, this.emailToken)
				.then(() => {
					this.buttonLoading = false;
					this.toast.success(this.$t('success.registrationCompleted'));
					this.$router.push({ name: 'login' });
				})
				.catch((error: Error) => {
					if (error.message == 'RegistrationNotCompleted')
						this.toast.error(this.$t('error.auth.passwordNotInitialized'));
					else this.toast.error(this.$t('error.auth.updatePassword'));

					this.buttonLoading = false;
				});
		}
	}

	checkPasswordValidity(): boolean {
		let validatePassword = true;

		if (this.password != this.passwordCheck && this.password != '' && this.passwordCheck != '') {
			this.pwdErrors.pwdCheck = this.$t('error.auth.differentPasswords');
			validatePassword = false;
		}

		if (!this.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{12,}$/gi)) {
			this.pwdErrors.pwd = this.$t('error.auth.passwordStrength');
			validatePassword = false;
		}

		if (!this.password) {
			this.pwdErrors.pwd = this.$t('error.emptyField');
			validatePassword = false;
		}

		if (!this.passwordCheck) {
			this.pwdErrors.pwdCheck = this.$t('error.emptyField');
			validatePassword = false;
		}

		return validatePassword;
	}
}
