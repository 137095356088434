import { Organisation } from '@/types/salesforce-objects.type';
import EnvironmentsService from './environments.service';
import CalloutAppApiService from './callout-app-api.service';
import { useSalesforceStore } from '@/stores/salesforce.store';
import { useEnvironmentStore } from '@/stores/environment.store';

export default class OrgInformationsService {
	static sfStore: ReturnType<typeof useSalesforceStore>;
	static envStore: ReturnType<typeof useEnvironmentStore>;

	/**
	 * Retourne l'id de l'org depuis son nom
	 * @param name nom de l'org
	 * @returns Id de l'org grace à son nom
	 */
	static async getOrgId(): Promise<string> {
		this.sfStore = useSalesforceStore();
		this.envStore = useEnvironmentStore();
		let allEnvironments = [];

		if (this.sfStore.orgId) return this.sfStore.orgId;

		if (this.envStore.externalEnvironments) allEnvironments = this.envStore.externalEnvironments;
		else allEnvironments = await EnvironmentsService.getExternalEnvironments();

		const prodOrgId = allEnvironments
			.filter((env) => env.typeOrg.toLowerCase() == 'prod')[0]
			.externalId.slice(0, -3);

		return prodOrgId;
	}

	/**
	 * Recupere toute les objets Consumptions__c pour le contrat précisé en params
	 * @param contractId Id du contrat
	 * @param orgId Id de l'organisation salesforce
	 * @returns Consommation
	 */
	static async getOrgInfos(): Promise<Organisation> {
		this.sfStore = useSalesforceStore();
		if (this.sfStore.orgInformations) return this.sfStore.orgInformations;
		const orgId = await this.getOrgId();

		return CalloutAppApiService.calloutAsync(
			'get',
			`${process.env.VUE_APP_APP_API_URI}/api/salesforce/org/${orgId}/infos`,
			''
		)
			.then((result) => {
				this.sfStore.orgInformations = result.records[0];
				return result.records[0];
			})
			.catch((err) => {
				console.error('Error GetOrgInfos :');
				console.error(err);

				throw new Error();
			});
	}
}
