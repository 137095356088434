
import { Vue, Options } from 'vue-class-component';
import { useAuthStore } from '@/stores/authentication.store';
import { useToast } from 'vue-toastification';

import UserInfosCard from '@/components/user/UserInfosCard.vue';
import TicketComponent from '@/components/general/TicketComponent.vue';
import UserDeleteModal from '@/components/user/UserDeleteModal.vue';
import UserCreateModal from './UserCreateModal.vue';

import UserService from '@/services/user.service';
import { GonexaUser } from '@/types/gonexa-users.type';
import UserEditModal from './UserEditModal.vue';

@Options({
	components: {
		UserInfosCard,
		TicketComponent,
		UserDeleteModal,
		UserCreateModal,
		UserEditModal,
	},
})
export default class UserView extends Vue {
	authStore = useAuthStore();
	toast = useToast();
	userLoaded = false;
	nbActiveUsers = 0;

	usersList = [] as GonexaUser[];
	modalUser = {} as GonexaUser;
	userToEdit = {} as GonexaUser;

	isCreateModalopen = false;
	isEditModalopen = false;
	isDeleteModalopen = false;

	isUsernameSorted = 1;
	isNameSorted = 0;
	isEmailSorted = 0;

	async mounted() {
		if (
			this.authStore.username &&
			this.authStore.firstname &&
			this.authStore.lastname &&
			this.authStore.email &&
			this.authStore.roleName &&
			this.authStore.createdDate
		) {
			this.usersList.push({
				username: this.authStore.username,
				firstName: this.authStore.firstname,
				lastName: this.authStore.lastname,
				email: this.authStore.email,
				roleName: this.authStore.roleName,
				createdDate: this.authStore.createdDate,
			});

			const result = await UserService.getCompanyUsers();
			const sortedUsers = result?.sort((x, y) => x.username.localeCompare(y.username));
			this.usersList.push(...sortedUsers);

			this.nbActiveUsers = this.usersList.length;
			this.userLoaded = true;
		}
	}

	openCreateModal() {
		this.isCreateModalopen = true;
	}
	addNewUser(user: GonexaUser) {
		this.usersList.push(user);
	}

	openDeleteModal(userToDelete: GonexaUser, index: number) {
		this.hideActions(index);
		this.modalUser = userToDelete;
		this.isDeleteModalopen = true;
	}
	deleteUser(user: GonexaUser) {
		UserService.deleteCompanyUser(user)
			.then(() => {
				this.toast.success(this.$t('success.userDeleted'));
				this.closeModal();
				this.nbActiveUsers--;
				this.usersList = this.usersList.filter((x) => x.username != user.username);
			})
			.catch(() => {
				this.toast.error(this.$t('error.deleteUser'));
			});
	}

	openEditModal(user: GonexaUser, index: number) {
		this.hideActions(index);
		this.userToEdit = user;
		this.isEditModalopen = true;
	}
	updateUserList(originalUsername: string, editedUser: GonexaUser) {
		this.usersList.forEach((elem) => {
			if (elem.username == originalUsername) {
				const index = this.usersList.indexOf(elem);
				this.usersList[index] = editedUser;
			}
		});
	}

	closeModal() {
		this.isEditModalopen = false;
		this.isDeleteModalopen = false;
		this.isCreateModalopen = false;
	}

	sortTableColumn(type: string) {
		let newSortedList = [];

		if (type == 'name') {
			// Si 0, alors pas trié, donc on tri en croissant
			// Si 1, trié en croissants, donc on trie en décroissant
			// Si -1, trié en décroissant, dont on trie en croissant

			newSortedList =
				this.isNameSorted == 1
					? this.usersList.sort((x, y) => x.firstName.localeCompare(y.firstName)).reverse()
					: this.usersList.sort((x, y) => x.firstName.localeCompare(y.firstName));

			// Suite au tri, on met à jour la variable pour qu'elle soit cohérente
			this.isNameSorted = this.isNameSorted == 1 ? -1 : 1;

			// Et on remet les deux autres colonnes non triées à 0
			this.isUsernameSorted = 0;
			this.isEmailSorted = 0;
		} else if (type == 'email') {
			newSortedList =
				this.isEmailSorted == 1
					? this.usersList.sort((x, y) => x.email.localeCompare(y.email)).reverse()
					: this.usersList.sort((x, y) => x.email.localeCompare(y.email));

			this.isEmailSorted = this.isEmailSorted == 1 ? -1 : 1;
			this.isUsernameSorted = 0;
			this.isNameSorted = 0;
		} else {
			newSortedList =
				this.isUsernameSorted == 1
					? this.usersList.sort((x, y) => x.username.localeCompare(y.username)).reverse()
					: this.usersList.sort((x, y) => x.username.localeCompare(y.username));

			this.isUsernameSorted = this.isUsernameSorted == 1 ? -1 : 1;
			this.isNameSorted = 0;
			this.isEmailSorted = 0;
		}

		this.usersList = newSortedList;
	}

	showActions(index: number) {
		const moreButton = document.getElementById('button-' + index);
		const actionsGroup = document.getElementById('group-' + index);

		if (moreButton && actionsGroup) {
			if (index == 0 && index + 1 == this.usersList.length) actionsGroup.style.top = '-36px';
			else if (index + 1 == this.usersList.length) actionsGroup.style.top = '-76px';
			else actionsGroup.style.top = '16px';
			moreButton.style.display = 'none';
			actionsGroup.style.display = 'flex';
		}
	}

	hideActions(index: number) {
		const moreButton = document.getElementById('button-' + index);
		const actionsGroup = document.getElementById('group-' + index);

		if (moreButton && actionsGroup) {
			moreButton.style.display = 'flex';
			actionsGroup.style.display = 'none';
		}
	}

	copyId(username: string, index: number) {
		this.hideActions(index);

		UserService.getUserId(username)
			.then((userId) => {
				navigator.clipboard.writeText(userId);
				this.toast.success(this.$t('users.idCopied'));
			})
			.catch(() => {
				this.toast.error(this.$t('error.idNotCopied'));
			});
	}
}
