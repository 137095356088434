import { defineComponent, PropType, h } from 'vue';

const props = {
	iconName: {
		type: String as PropType<string>,
	},
	closeToast: {
		type: Function as PropType<(e?: MouseEvent) => void>,
	},
};

export const ToastButton = defineComponent({
	props,
	setup(props) {
		return () =>
			h(
				'button',
				{
					onClick: props.closeToast,
					style: {
						color: 'white',
						margin: '0',
						opacity: 1,
						width: '45px',
						height: '45px',
					},
				},
				[
					h('img', {
						src: require('@/assets/svg/' + props.iconName),
						style: {
							width: '45px',
							height: '45px',
						},
					}),
				]
			);
	},
});

export const ToastIcon = defineComponent({
	props,
	setup(props) {
		return () =>
			h('img', {
				src: require('@/assets/svg/' + props.iconName),
				style: {
					width: '25px',
					height: '25px',
				},
			});
	},
});

export const ToastButtonSuccess = h(ToastButton, { iconName: 'cross_green.svg' });

export const ToastButtonError = h(ToastButton, { iconName: 'cross_red.svg' });

export const ToastButtonWarning = h(ToastButton, { iconName: 'cross_yellow.svg' });

export const ToastButtonInfo = h(ToastButton, { iconName: 'cross_blue.svg' });

export const ToastErrorIcon = h(ToastIcon, { iconName: 'close_circle.svg' });

export const ToastSuccessIcon = h(ToastIcon, { iconName: 'check_circle_green_new.svg' });

export const ToastInfoIcon = h(ToastIcon, { iconName: 'info_circle.svg' });

export const ToastWarningIcon = h(ToastIcon, { iconName: 'warning_circle.svg' });
