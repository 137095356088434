import axios, { AxiosError } from 'axios';
import { useToast } from 'vue-toastification';
import { RegisterUserData, RegisteredUserData, LoggedUserData } from '@/types/gonexa-auth.type';
import { Vue } from 'vue-class-component';
import { useAuthStore } from '@/stores/authentication.store';

// Classe contenant toutes les méthodes d'appels API liés à l'authentification du compte Gonexa
export default class AuthGonexaService {
	static toast = useToast();
	static authStore: ReturnType<typeof useAuthStore>;

	/**
	 * Recupère l'utilisateur connecté stocké dans le localStorage
	 * @returns true si un utilisateur est connecté, false sinon ou si l'accès au Local Storage est impossible
	 */
	static async getLocalUser(): Promise<boolean> {
		this.authStore = useAuthStore();
		const localUserToken = localStorage.getItem('APP_U');

		// Si aucun utilisateur dans le store mais qu'un UserToken est stocké dans le Local Storage,
		// on récupère les infos liées à ce UserToken et on stocke l'utilisateur dans le store
		if (this.authStore.userToken === null && localUserToken) {
			return await axios
				.get(`${process.env.VUE_APP_APP_API_URI}/api/user`, {
					headers: { Authorization: `Bearer ${localUserToken}` },
				})
				.then(({ data }) => {
					data.userToken = localUserToken;
					this.authStore.logUser(data);
					return true;
				})
				.catch((error) => {
					console.error(`😕 Erreur de récupération de l'utilisateur local - ${error.response.data}`);
					console.error(error);

					if (error.response.data === 'ExpiredUserAccessToken') {
						this.toast.info(Vue.prototype.$t('error.tokenExpired'));
					}

					localStorage.removeItem('APP_U');
					return false;
				});
		} else if (this.authStore.userToken !== null) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Recupère l'utilisateur connecté stocké dans le localStorage
	 * @returns true si un utilisateur est connecté, false sinon ou si l'accès au Local Storage est impossible
	 */
	static async getCompanyStatus(): Promise<string> {
		this.authStore = useAuthStore();

		return await axios
			.get(`${process.env.VUE_APP_APP_API_URI}/api/company/status`, {
				headers: { Authorization: `Bearer ${this.authStore.userToken}` },
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error(`😕 Erreur de récupération du statut de la company`);
				console.error(error);

				if (error.response?.data === 'CompanyDoesNotExists') {
					this.toast.info(Vue.prototype.$t('error.companyNotIdentified'));
				} else throw new Error();
			});
	}

	/**
	 * Méthode qui permet la création d'un nouveau compte
	 * @param userDetails : Données d'inscription saisies par l'utilisateur, envoyées dans le body de la l'appel
	 * @returns RegisteredUserData : Si le compte a pû être créé et le mail de validation envoyé, on retourne l'utilisateur en cours de création. Sinon, une erreur est émise.
	 */
	static async register(userDetails: RegisterUserData): Promise<RegisteredUserData> {
		return await axios
			.post(`${process.env.VUE_APP_APP_API_URI}/api/auth/register`, {
				username: userDetails.username,
				firstname: userDetails.firstname,
				lastname: userDetails.lastname,
				email: userDetails.email,
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error(`😕 Erreur dans la création du nouvel utilisateur "${userDetails.username}."`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	/**
	 * Méthode qui permet la connexion à un compte Gonexa
	 * @param userDetails : Données de connexion saisies par l'utilisateur, envoyées dans le body de la l'appel
	 * @returns LoggedUserData : Objet contenant les données de l'utilisateur connecté
	 */
	static async login(username: string, encryptedPassword: string): Promise<LoggedUserData> {
		return await axios
			.post(`${process.env.VUE_APP_APP_API_URI}/api/auth/login`, {
				username: username,
				encryptedPassword: encryptedPassword,
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error(`😕 Erreur dans la connexion de l'utilisateur "${username}."`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	static async loginAs(username: string, usertoken: string): Promise<LoggedUserData> {
		return await axios
			.post(
				`${process.env.VUE_APP_APP_API_URI}/api/auth/loginAs`,
				{
					username: username,
				},
				{
					headers: {
						Authorization: `Bearer ${usertoken}`,
					},
				}
			)
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error(`😕 Erreur dans la connexion de l'utilisateur "${username}."`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	/**
	 * Vérifie si le mail-token est bon et renvoie l'utilisateur associé
	 * @param emailtoken : Token genéré en Back et transmis par mail
	 * @param type : Type de la route, verification pour creation du mot de passe ou sa reinitialization
	 * @returns verifiedEmail : Mail vérifié retourné si le token est bon, sinon une erreur est émise
	 */
	static async verifyAccount(emailToken: string, isFirstVerification: boolean): Promise<RegisteredUserData> {
		return await axios
			.post(
				`${process.env.VUE_APP_APP_API_URI}/api/auth/verify`,
				{
					emailToken: emailToken,
					init: isFirstVerification,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error(`😕 Erreur dans la vérification du compte de l'utilisateur.`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	/**
	 * Envoie le mail de verification
	 * @param username : username utilisateur
	 * @param email : email utilisateur
	 * @param isFirstVerif : booléen si c'est la première vérification du mail (inscription) ou non (édition du compte)
	 * @returns RegisteredUserData : username et mail à qui a été envoyé la vérification
	 */
	static async sendEmail(username: string, email: string, isFirstVerif: boolean): Promise<RegisteredUserData> {
		localStorage.setItem('APP_U_M', JSON.stringify({ username: username, email: email }));
		const url = isFirstVerif ? 'first-verification' : 'verification';

		return await axios
			.post(`${process.env.VUE_APP_APP_API_URI}/api/email/${url}`, {
				username: username,
				email: email,
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error(`😕 Erreur dans l'envoi d'un mail de vérification.`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	/**
	 * Méthode pour initialiser le mot de passe de l'utilisateur
	 * @param password : Mot de passe saisi par l'utilisateur
	 * @param username : Nom du compte utilisateur qui initialise son mot de passe
	 * @param token : token passé dans l'url (emailToken)
	 * @returns utilisateur qui a créé sont mdp, sinon une erreur est émise
	 */
	static async createPassword(password: string, username: string, token: string) {
		return await axios
			.post(
				`${process.env.VUE_APP_APP_API_URI}/api/user/password`,
				{
					encryptedPassword: password,
					username: username,
				},
				{
					headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
				}
			)
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error(`😕 Erreur dans le changement de mot de passe.`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	/**
	 * Méthode pour mettre à jour le mot de passe de l'utilisateur
	 * @param password : Mot de passe saisi par l'utilisateur
	 * @param username : Nom du compte utilisateur qui initialise son mot de passe
	 * @param token : token passé dans l'url (emailToken)
	 * @returns utilisateur qui a mis à jour son mdp, sinon une erreur est émise
	 */
	static async updatePassword(password: string, username: string, token: string) {
		return await axios
			.patch(
				`${process.env.VUE_APP_APP_API_URI}/api/user/password`,
				{
					encryptedPassword: password,
					username: username,
				},
				{
					headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
				}
			)
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error(`😕 Erreur dans le changement de mot de passe.`);
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	static async disconnectUser(token: string) {
		return axios.post(
			`${process.env.VUE_APP_APP_API_URI}/api/user/disconnect`,
			{},
			{
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
			}
		);
	}
}
