import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e5c1526"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth__confirm-mail" }
const _hoisted_2 = {
  key: 0,
  class: "auth__title"
}
const _hoisted_3 = {
  key: 1,
  class: "auth__title"
}
const _hoisted_4 = { class: "mail-sent__body" }
const _hoisted_5 = { class: "auth__text" }
const _hoisted_6 = {
  key: 0,
  class: "auth__text"
}
const _hoisted_7 = {
  key: 1,
  class: "auth__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name == 'email-sent')
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('auth.emails.confirm')), 1))
      : _createCommentVNode("", true),
    (_ctx.$route.name == 'lost-sent')
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('auth.password.reset')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.emails.sent')) + " ", 1),
        _createElementVNode("b", null, _toDisplayString(_ctx.user.email), 1)
      ]),
      (_ctx.$route.name == 'email-sent')
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('auth.emails.consult')), 1))
        : _createCommentVNode("", true),
      (_ctx.$route.name == 'lost-sent')
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('auth.emails.consultReset')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", {
      class: "gnx-btn _primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resendEmail()))
    }, _toDisplayString(_ctx.$t('auth.emails.resend')), 1)
  ]))
}