import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44b36d42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "register" }
const _hoisted_2 = { class: "register__header" }
const _hoisted_3 = { class: "auth__title" }
const _hoisted_4 = { class: "auth__text" }
const _hoisted_5 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_6 = { class: "gnx-label" }
const _hoisted_7 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_8 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_9 = { class: "gnx-label" }
const _hoisted_10 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_11 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_12 = { class: "gnx-label" }
const _hoisted_13 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_14 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_15 = { class: "gnx-label" }
const _hoisted_16 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_17 = ["type"]
const _hoisted_18 = {
  key: 0,
  class: "app-spinner --small"
}
const _hoisted_19 = { class: "register__login" }
const _hoisted_20 = {
  class: "register__login-link",
  href: "/login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('auth.welcome')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('auth.registerIntro')), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.registerNewUser && _ctx.registerNewUser(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('auth.inputs.firstname')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.inputErrors.firstname }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUser.firstname) = $event)),
          onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputErrors.firstname = !_ctx.newUser.firstname ? _ctx.$t('error.emptyField') : ''))
        }, null, 34), [
          [_vModelText, _ctx.newUser.firstname]
        ]),
        (_ctx.inputErrors.firstname)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.inputErrors.firstname), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('auth.inputs.lastname')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.inputErrors.lastname }]),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUser.lastname) = $event)),
          onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputErrors.lastname = !_ctx.newUser.lastname ? _ctx.$t('error.emptyField') : ''))
        }, null, 34), [
          [_vModelText, _ctx.newUser.lastname]
        ]),
        (_ctx.inputErrors.lastname)
          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.inputErrors.lastname), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('auth.inputs.email')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "mail",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.inputErrors.email }]),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newUser.email) = $event)),
          onFocusout: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onFocusOutEmail && _ctx.onFocusOutEmail(...args)))
        }, null, 34), [
          [_vModelText, _ctx.newUser.email]
        ]),
        (_ctx.inputErrors.email)
          ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.inputErrors.email), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('auth.inputs.username')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.inputErrors.username }]),
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newUser.username) = $event)),
          onFocusout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.inputErrors.username = !_ctx.newUser.username ? _ctx.$t('error.emptyField') : '')),
          onInput: _cache[8] || (_cache[8] = ($event: any) => {
						if (_ctx.inputErrors.username == _ctx.$t('error.auth.usernameAlreadyExists')) _ctx.inputErrors.username = '';
					})
        }, null, 34), [
          [_vModelText, _ctx.newUser.username]
        ]),
        (_ctx.inputErrors.username)
          ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.inputErrors.username), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("button", {
        type: !_ctx.registerLoading ? 'submit' : 'button',
        class: "register__submit gnx-btn _primary app-form__btn"
      }, [
        _createTextVNode(_toDisplayString(!_ctx.registerLoading ? _ctx.$t('auth.buttons.createAccount') : '') + " ", 1),
        (_ctx.registerLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18))
          : _createCommentVNode("", true)
      ], 8, _hoisted_17)
    ], 32),
    _createElementVNode("p", _hoisted_19, [
      _createTextVNode(_toDisplayString(_ctx.$t('auth.alreadyAccount')) + " ", 1),
      _createElementVNode("a", _hoisted_20, _toDisplayString(_ctx.$t('auth.buttons.login')), 1)
    ])
  ]))
}