
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { Contract } from '@/types/salesforce-objects.type';
import OrgContractService from '@/services/org-contract.service';

export default class ConsumptionCardsGroup extends Vue {
	toast = useToast();
	currentContract: Contract | undefined = undefined;
	contractLoaded = false;

	contractValue = 0;
	contractLimit = 0;
	templatesValue = 0;
	signValue = 0;
	signLimit = 0;

	async mounted() {
		try {
			this.currentContract = await OrgContractService.getCurrentContract();
			this.contractLoaded = true;

			if (this.currentContract) {
				this.contractValue = this.currentContract.real_generation__c;
				this.contractLimit = this.currentContract.gnx__Number_of_generations__c;
				this.templatesValue = this.currentContract.gnx__Number_of_templates__c
					? this.currentContract.gnx__Number_of_templates__c
					: 0;
				this.signLimit = this.currentContract.authorized_sign__c;
			}
		} catch (error) {
			this.contractLoaded = true;
		}
	}

	/**
	 * Retourne un chiffre (5000 par exemple) sous forme simplifié (5K)
	 * @param number nombre
	 */
	formatCompactNumber(number: number) {
		if (number < 1000) {
			return number;
		} else if (number >= 1000 && number < 1_000_000) {
			return (number / 1000).toFixed(1) + 'K';
		} else if (number >= 1_000_000 && number < 1_000_000_000) {
			return (number / 1_000_000).toFixed(1) + 'M';
		} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
			return (number / 1_000_000_000).toFixed(1) + 'B';
		} else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
			return (number / 1_000_000_000_000).toFixed(1) + 'T';
		}
	}
}
