import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svg/eye-grey.svg'
import _imports_1 from '@/assets/svg/eye-crossed-grey.svg'


const _withScopeId = n => (_pushScopeId("data-v-95c0309c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login__header" }
const _hoisted_3 = { class: "auth__title" }
const _hoisted_4 = { class: "auth__text" }
const _hoisted_5 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_6 = { class: "gnx-label" }
const _hoisted_7 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_8 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_9 = { class: "gnx-label" }
const _hoisted_10 = ["type"]
const _hoisted_11 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_12 = {
  key: 0,
  class: "password__eye-icon",
  src: _imports_0,
  alt: ""
}
const _hoisted_13 = {
  key: 1,
  class: "password__eye-icon",
  src: _imports_1,
  alt: ""
}
const _hoisted_14 = {
  class: "login__forget",
  href: "/lost-password"
}
const _hoisted_15 = {
  key: 0,
  class: "app-spinner --small"
}
const _hoisted_16 = { class: "login__register" }
const _hoisted_17 = {
  class: "login__register-link",
  href: "/register"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('auth.welcome')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('auth.loginIntro')), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.loginUser && _ctx.loginUser(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('auth.inputs.username')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.inputErrors.username }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gnxUser.username) = $event)),
          onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputErrors.username = !_ctx.gnxUser.username ? _ctx.$t('error.emptyField') : '')),
          onInput: _cache[2] || (_cache[2] = ($event: any) => {if (_ctx.inputErrors.username == _ctx.$t('error.auth.unknownUsername')) _ctx.inputErrors.username = '';})
        }, null, 34), [
          [_vModelText, _ctx.gnxUser.username]
        ]),
        (_ctx.inputErrors.username)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.inputErrors.username), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('auth.inputs.password')), 1),
        _withDirectives(_createElementVNode("input", {
          type: _ctx.seePassword ? 'text' : 'password',
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.inputErrors.encryptedPassword }]),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.gnxUser.password) = $event)),
          onFocusout: _cache[4] || (_cache[4] = ($event: any) => (_ctx.inputErrors.encryptedPassword = !_ctx.gnxUser.password ? _ctx.$t('error.emptyField') : '')),
          onInput: _cache[5] || (_cache[5] = ($event: any) => {
						if (_ctx.inputErrors.encryptedPassword == _ctx.$t('error.auth.wrongPassword'))
							_ctx.inputErrors.encryptedPassword = '';
					})
        }, null, 42, _hoisted_10), [
          [_vModelDynamic, _ctx.gnxUser.password]
        ]),
        (_ctx.inputErrors.encryptedPassword)
          ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.inputErrors.encryptedPassword), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "password__eye-btn",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.seePassword = !_ctx.seePassword))
        }, [
          (_ctx.seePassword)
            ? (_openBlock(), _createElementBlock("img", _hoisted_12))
            : (_openBlock(), _createElementBlock("img", _hoisted_13))
        ])
      ]),
      _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.$t('auth.buttons.forgotPassword')), 1),
      _createElementVNode("button", {
        type: "submit",
        class: "login__submit gnx-btn _primary app-form__btn",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (!_ctx.loginLoading ? _ctx.loginUser : ''))
      }, [
        _createTextVNode(_toDisplayString(!_ctx.loginLoading ? _ctx.$t('auth.buttons.login') : '') + " ", 1),
        (_ctx.loginLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createElementVNode("p", _hoisted_16, [
      _createTextVNode(_toDisplayString(_ctx.$t('auth.noAccount')) + " ", 1),
      _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.$t('auth.buttons.register')), 1)
    ])
  ]))
}