
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import AuthGonexaService from '@/services/auth-gonexa.service';

export default class LostPasswordForm extends Vue {
	toast = useToast();
	buttonLoading = false;

	username = '';
	usernameError = '';

	email = '';
	emailError = '';

	async sendForgotPasswordEmail() {
		this.buttonLoading = true;

		if (!this.username) {
			this.usernameError = this.$t('error.emptyField');
		} else if (!this.email) {
			this.emailError = this.$t('error.emptyField');
		} else {
			await AuthGonexaService.sendEmail(this.username, this.email, false)
				.then(() => {
					this.buttonLoading = false;
					this.$router.push({ name: 'lost-sent' });
				})
				.catch((error: Error) => {
					if (error.message == 'WrongEmailForUsername') this.toast.error(this.$t('error.email.wrongAddress'));
					else if (error.message == 'UserDoesNotExist')
						this.toast.error(this.$t('error.auth.unknownUsername'));
					else this.toast.error(this.$t('error.email.send'));

					this.buttonLoading = false;
				});
		}

		this.buttonLoading = false;
	}
}
