import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d66d8adc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticket app-card" }
const _hoisted_2 = { class: "ticket__question" }
const _hoisted_3 = { class: "ticket__question-text" }
const _hoisted_4 = { class: "ticket__question-text --blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('ticketCard.question')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('ticketCard.contact')), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        class: "gnx-btn _primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectTo('https://university.gonexa.fr')))
      }, _toDisplayString(_ctx.$t('ticketCard.open')), 1)
    ])
  ]))
}