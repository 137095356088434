
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import ApexCharts from 'apexcharts';

import OrgContractService from '@/services/org-contract.service';
import { Consumption, Contract } from '@/types/salesforce-objects.type';

export default class ConsumptionChart extends Vue {
	toast = useToast();

	generationPercentage = '';
	generationLimit = '';
	error = false;
	contractLoaded = false;
	graphName = 'doc';

	consumption: Consumption[] | undefined = undefined;
	clientContract: Contract | undefined = undefined;

	async mounted() {
		try {
			this.clientContract = await OrgContractService.getCurrentContract();

			this.clientContract.authorized_RF__c = 0; // /!\ A ENLEVER LORSE QU'IL Y AURA LES CONSOS  RF

			// Si un contrat existe, je récupère sa consommation
			if (this.clientContract) {
				this.consumption = await OrgContractService.getConsumptions(this.clientContract.id);

				if (this.consumption) {
					this.generationLimit = this.clientContract.gnx__Number_of_generations__c.toString();
					let diviser = this.clientContract.gnx__Number_of_generations__c;

					this.generationPercentage =
						Math.round((100 * this.clientContract.real_generation__c) / diviser).toString() + '%';
					this.contractLoaded = true;
				}
			}
		} catch (error) {
			this.toast.error(this.$t('error.noConsumption'));
			this.contractLoaded = true;
		}
		this.$nextTick(() => {
			if (this.consumption) this.initGenerationInfos(this.consumption);
		});
	}
	/**
	 * Initialise le graphique
	 * @param consumptions toutes les consommations du contrat
	 */
	initGenerationInfos(consumptions: Consumption[], type = 'doc') {
		this.graphName = type;
		const chartElm = document.querySelector('#consoTodayGraph');
		if (!chartElm) return;

		// Vidage du graphique s'il y en a déjà un
		while (chartElm?.firstChild) {
			chartElm.removeChild(chartElm?.firstChild);
		}

		const lastYearDate = new Date();
		lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);
		consumptions = consumptions.filter(
			(item) =>
				new Date(item.month__c) >= lastYearDate &&
				new Date(item.month__c) <= new Date() &&
				item.product_Type__c.toLocaleLowerCase() == type.toLocaleLowerCase()
		);

		const options = {
			chart: {
				height: '400px',
				type: 'line',
				toolbar: {
					show: false,
				},
				selection: {
					enabled: false,
					type: 'x',
				},
			},
			stroke: {
				width: 2,
				curve: 'smooth',
			},
			grid: {
				show: false,
			},
			yaxis: {
				show: true,
			},
			labels: consumptions.map((elem: Consumption) => {
				return elem.month__c;
			}),
			colors: [type == 'doc' ? '#0571BC' : '#E58A80', '#F78F53'],
			series: [
				{
					name: this.$t('consumption.actual'),
					type: 'line',
					data: consumptions.map((elem: Consumption) => {
						const index = consumptions.indexOf(elem);
						return (
							(index > -1 ? consumptions.slice(0, index + 1) : []).reduce(
								(accumulator, currentValue) => accumulator + currentValue.generationNumber__c,
								0
							) ?? 0
						);
					}),
				},
				{
					name: this.$t('consumption.limit'),
					type: 'line',
					data: Array(consumptions.length)
						.fill(consumptions.length)
						.map(() => this.clientContract?.authorized_sign__c ?? 0),
				},
			],
		};

		// Affichage du graphique
		const chart = new ApexCharts(chartElm, options);
		chart.render();
	}
}
