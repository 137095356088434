
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { RegisterUserData, RegisteredUserData } from '@/types/gonexa-auth.type';
import AuthGonexaService from '@/services/auth-gonexa.service';
import { useAuthStore } from '@/stores/authentication.store';

interface RegisterInputs {
	firstname: string;
	lastname: string;
	email: string;
	username: string;
}

export default class RegisterForm extends Vue {
	toast = useToast();
	authStore = useAuthStore();
	registerLoading = false;

	newUser = {} as RegisterUserData; // Objet qui contient les donénes saisies par l'utilisateur
	inputErrors = {} as RegisterInputs; // Objet qui contient les messages erreurs associés à chaque champ de saisie

	onFocusOutEmail() {
		this.inputErrors.email = !this.newUser.email ? this.$t('error.emptyField') : '';
		if (
			(this.newUser.username == null || this.newUser.username == '') &&
			this.newUser.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi)
		)
			this.newUser.username = this.newUser.email;
	}

	/**
	 * Méthode qui déclenche la création d'un nouveau compte Gonexa en base si les données sont valides
	 */
	async registerNewUser() {
		if (this.checkInputErrors()) {
			this.registerLoading = true;

			AuthGonexaService.register(this.newUser)
				.then((response: RegisteredUserData) => {
					this.registerLoading = false;

					const userLocalData = JSON.stringify({ username: response.username, email: response.email });
					localStorage.setItem('APP_U_M', userLocalData);
					this.$router.push({ name: 'email-sent' });
				})
				.catch((error: Error) => {
					this.registerLoading = false;

					switch (error.message) {
						case 'UsernameAlreadyExists':
							this.inputErrors.username = this.$t('error.auth.usernameAlreadyExists');
							break;
						case 'RegistrationAlreadyCompleted':
							this.toast.error(this.$t('error.auth.alreadyRegistered'));
							break;
						case 'SendingMailError' || 'SmtpClientError' || 'MailTokenError':
							this.toast.error(this.$t('error.email.send'));
							break;
						default:
							this.toast.error(this.$t('error.auth.register'));
							break;
					}
				});
		}
	}

	/**
	 * Méthode de vérification des données saisies par l'utilisateur avant qu'elles ne soient envoyées à l'API pour inscription
	 * @returns boolean : true si les données sont valides et peuvent être envoyées, fales sinon
	 */
	checkInputErrors(): boolean {
		let validateRegistration = true;

		if (!this.newUser.firstname) {
			this.inputErrors.firstname = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.lastname) {
			this.inputErrors.lastname = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.email) {
			this.inputErrors.email = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi)) {
			this.inputErrors.email = this.$t('error.email.wrongFormat');
			validateRegistration = false;
		}

		if (!this.newUser.username) {
			this.inputErrors.username = this.$t('error.emptyField');
			validateRegistration = false;
		}

		if (!this.newUser.username.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi)) {
			this.inputErrors.username = this.$t('error.auth.username');
			validateRegistration = false;
		}

		return validateRegistration;
	}
}
