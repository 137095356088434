import { ExternalEnvironnement, ExternalUser } from '@/types/gonexa-users.type';
import { useToast } from 'vue-toastification';
import CalloutAppApiService from './callout-app-api.service';
import { useEnvironmentStore } from '@/stores/environment.store';
import { Vue } from 'vue-class-component';

export default class EnvironmentsService {
	static toast = useToast();
	static environmentStore: ReturnType<typeof useEnvironmentStore>;

	static async getExternalEnvironments(): Promise<ExternalEnvironnement[]> {
		this.environmentStore = useEnvironmentStore();

		if (this.environmentStore.externalEnvironments) return this.environmentStore.externalEnvironments;
		try {
			this.environmentStore.externalEnvironments = (await CalloutAppApiService.calloutAsync(
				'GET',
				`${process.env.VUE_APP_APP_API_URI}/api/company/externalenvironments`
			)) as ExternalEnvironnement[];
			return this.environmentStore.externalEnvironments;
		} catch (error) {
			console.error('🏢 Erreur de récupération des External Environments');
			console.error(error);

			throw new Error();
		}
	}

	static async getExternalUsers(): Promise<ExternalUser[]> {
		this.environmentStore = useEnvironmentStore();
		if (this.environmentStore.externalUsers) return this.environmentStore.externalUsers;

		try {
			this.environmentStore.externalUsers = (await CalloutAppApiService.calloutAsync(
				'get',
				`${process.env.VUE_APP_APP_API_URI}/api/user/externalusers`
			)) as ExternalUser[];

			return this.environmentStore.externalUsers;
		} catch (error) {
			console.error('🏢 Erreur de récupération des External Users');
			console.error(error);

			throw new Error();
		}
	}

	/* UNUSED : 

	static addExternalUser(
		username: string,
		type: string,
		userId: string,
		externalId: string,
		orgId: string,
		refreshToken: string,
		accessToken: string,
		instanceUrl: string,
		typeOrg: string,
		companyId: string,
		orgname: string
	) {
		return CalloutAppApiService.calloutAsync('post', `${process.env.VUE_APP_APP_API_URI}/api/externaluser/`, {
			username: username,
			type: type,
			userId: userId,
			externalId: externalId,
			orgId: orgId,
			refreshToken: btoa(refreshToken),
			accessToken: accessToken,
			instanceUrl: instanceUrl,
			typeOrg: typeOrg,
			companyId: companyId,
			OrgName: orgname,
		})
			.then(() => {
				this.toast.success(Vue.prototype.$t('success.connectEnv'));
			})
			.catch((error) => {
				console.error("🏢 Erreur lors de la connexion d'un External Environment :");
				console.error(error);

				if (error.response.data == 'ExternalUserAlreadyExist')
					this.toast.info(`Vous êtes déjà connecté à cette environnement`);
				else if (error.response.data == 'ExternalEnvironmentProdAlreadyExist')
					this.toast.info(`Un environnement de production est dejà connecté`);
			});
	}
*/

	static deleteExternalUser(id: string) {
		this.environmentStore = useEnvironmentStore();
		return CalloutAppApiService.calloutAsync(
			'delete',
			`${process.env.VUE_APP_APP_API_URI}/api/externalenvironment/${id}`
		)
			.then(() => {
				this.environmentStore.deleteExternalEnvironment(id);
				this.toast.success(Vue.prototype.$t('success.deleteEnv'));
			})
			.catch((error) => {
				console.error("🏢 Erreur lors de la suppression d'un External Environment :");
				console.error(error);

				if (error.response.data == 'ExternalDoesNotExist') {
					this.toast.info(Vue.prototype.$t('error.environment.deleteUnknown'));
					this.environmentStore.deleteExternalEnvironment(id);
				} else this.toast.error(Vue.prototype.$t('error.environment.delete'));
			});
	}

	static async securiseExternalEnvironment(id: string): Promise<boolean> {
		this.environmentStore = useEnvironmentStore();
		return await CalloutAppApiService.calloutAsync(
			'post',
			`${process.env.VUE_APP_APP_API_URI}/api/externalenvironment/${id}/secure`
		)
			.then(() => {
				this.toast.success(Vue.prototype.$t('success.secureEnv'));
				this.environmentStore.securiseExternalEnvironment(id);
				return true;
			})
			.catch((error) => {
				this.toast.error(`🏢 Erreur lors de la sécurisation d'un External Environment :`);
				console.error(error);

				this.toast.error(Vue.prototype.$t('error.environment.secure'));
				return false;
			});
	}
}
