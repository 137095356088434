
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import UserService from '@/services/user.service';

export default class ConfirmEmail extends Vue {
	toast = useToast();
	user = {} as { username: string; email: string };
	isLoading = true;

	async mounted() {
		const emailToken = this.$route.params.emailToken.toString();
		if (!emailToken) this.$router.push({ name: 'home' });

		UserService.updateUserEmail(emailToken)
			.then((response) => {
				this.user = response;
				this.isLoading = false;
			})
			.catch((error: Error) => {
				console.log(error);

				switch (error.message) {
					case 'EmailNotModified': {
						this.toast.error(this.$t('error.email.notModified'));
						break;
					}
					default: {
						this.toast.error(this.$t('error.email.verifyNew'));
						break;
					}
				}

				this.$router.push({ name: 'home' });
			});
	}
}
