
import { Vue } from 'vue-class-component';

export default class ErrorView extends Vue {
	errormessage!: string;

	openSupport() {
		const win = window.open('https://aide.gonexa.fr/s/contactsupport', '_blank');
		if (win) win.focus();
	}

	homePage() {
		this.$router.push('/home');
	}
}
