
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import OrgContractService from '@/services/org-contract.service';
import { Contract } from '@/types/salesforce-objects.type';

export default class ContractInfosCard extends Vue {
	toast = useToast();
	infoLoaded = false;
	currentContract: Contract | undefined = undefined;

	async mounted() {
		try {
			this.currentContract = await OrgContractService.getCurrentContract();
			this.infoLoaded = true;
		} catch (error) {
			this.currentContract = {} as Contract;
			this.toast.error(this.$t('error.noContract'));

			this.infoLoaded = true;
		}
	}

	getFormattedDate(dateString: string) {
		try {
			if (dateString != undefined) {
				// Si c'est un format DATETIME, je ne garde que la partie DATE
				if (dateString.includes('T')) dateString = dateString.slice(0, 10);
				const dateParts: any = dateString.split('-');
				const date = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

				// Récupère le jour, le mois et l'année de la date
				const day = date.getDate().toString().padStart(2, '0');
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const year = date.getFullYear();

				if (this.$i18n.locale == 'fr') {
					// Formate la date en DD/MM/YYYY si l'App est en français
					return `${day}/${month}/${year}`;
				} else {
					// Formate la date en DD/MM/YYYY sinon
					return `${day}/${month}/${year}`;
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
}
