import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bef759a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "password" }
const _hoisted_2 = { class: "password__header" }
const _hoisted_3 = { class: "auth__title" }
const _hoisted_4 = { class: "auth__text" }
const _hoisted_5 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_6 = { class: "gnx-label" }
const _hoisted_7 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_8 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_9 = { class: "gnx-label" }
const _hoisted_10 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "app-spinner --small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('auth.password.reset')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('auth.password.resetIntro')), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendForgotPasswordEmail && _ctx.sendForgotPasswordEmail(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('auth.inputs.username')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.usernameError }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
          onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.usernameError = !_ctx.username ? _ctx.$t('error.emptyField') : ''))
        }, null, 34), [
          [_vModelText, _ctx.username]
        ]),
        (_ctx.usernameError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.usernameError), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('auth.inputs.email')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "mail",
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.emailError }]),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
          onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emailError = !_ctx.email ? _ctx.$t('error.emptyField') : ''))
        }, null, 34), [
          [_vModelText, _ctx.email]
        ]),
        (_ctx.emailError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.emailError), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("button", {
        type: "submit",
        class: "password__submit gnx-btn _primary app-form__btn",
        disabled: _ctx.buttonLoading
      }, [
        _createTextVNode(_toDisplayString(!_ctx.buttonLoading ? _ctx.$t('auth.buttons.reset') : '') + " ", 1),
        (_ctx.buttonLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12))
          : _createCommentVNode("", true)
      ], 8, _hoisted_11)
    ], 32)
  ]))
}