import { GonexaUser } from '@/types/gonexa-users.type';
import { Contract, Consumption, DocumentTemplate, Invoice, Organisation } from '@/types/salesforce-objects.type';
import { defineStore } from 'pinia';

export const useSalesforceStore = defineStore('salesforce', {
	state: () => ({
		orgId: null as string | null,

		orgInformations: null as Organisation | null,

		currentContract: null as Contract | null,

		invoices: null as Invoice[] | null,

		consumptions: null as Consumption[] | null,

		docTemplates: null as {
			[orgId: string]: DocumentTemplate[];
		} | null,

		companyUsers: null as GonexaUser[] | null,
		usersNb: 0,
	}),

	getters: {},

	actions: {
		deleteTemplates(orgId: string) {
			if (this.docTemplates != null && this.docTemplates[orgId] != null) {
				delete this.docTemplates[orgId];
			}
		},

		addCompanyUser(newUser: GonexaUser) {
			this.companyUsers?.push(newUser);
		},

		deleteCompanyUser(user: GonexaUser) {
			if (this.companyUsers != null) {
				const i = this.companyUsers.findIndex((u) => {
					return u.username == user.username;
				});
				if (i) this.companyUsers.splice(i, 1);
			}
		},
	},
});
