
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

export default class GonexaUniversityComponent extends Vue {
	toast = useToast();

	redirectTo(link: string) {
		try {
			window.open(link, '_blank');
		} catch (error) {
			this.toast.error(this.$t('error.redirect'));
		}
	}
}
