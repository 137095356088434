import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svg/check_green.svg'
import _imports_1 from '@/assets/svg/eye-grey.svg'
import _imports_2 from '@/assets/svg/eye-crossed-grey.svg'


const _withScopeId = n => (_pushScopeId("data-v-99a94de0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "password" }
const _hoisted_2 = { class: "password__header" }
const _hoisted_3 = { class: "auth__title" }
const _hoisted_4 = { class: "auth__text" }
const _hoisted_5 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_6 = { class: "gnx-label" }
const _hoisted_7 = ["type"]
const _hoisted_8 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_9 = {
  key: 1,
  src: _imports_0,
  class: "password__ok-icon"
}
const _hoisted_10 = {
  key: 0,
  class: "password__eye-icon",
  src: _imports_1,
  alt: ""
}
const _hoisted_11 = {
  key: 1,
  class: "password__eye-icon",
  src: _imports_2,
  alt: ""
}
const _hoisted_12 = { class: "app-form__field gnx-form-field _thin" }
const _hoisted_13 = { class: "gnx-label" }
const _hoisted_14 = ["type"]
const _hoisted_15 = {
  key: 0,
  class: "gnx-field-error"
}
const _hoisted_16 = {
  key: 1,
  src: _imports_0,
  class: "password__ok-icon",
  alt: ""
}
const _hoisted_17 = {
  key: 0,
  class: "password__eye-icon",
  src: _imports_1,
  alt: ""
}
const _hoisted_18 = {
  key: 1,
  class: "password__eye-icon",
  src: _imports_2,
  alt: ""
}
const _hoisted_19 = ["disabled"]
const _hoisted_20 = {
  key: 0,
  class: "app-spinner --small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$route.name == 'initialize-password' ? _ctx.$t('auth.welcome') : _ctx.$t('auth.password.reset')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('auth.password.defineFor')) + " " + _toDisplayString(_ctx.verifiedUsername), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.$route.name == 'initialize-password' ? _ctx.createPassword() : _ctx.updatePassword()), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('auth.inputs.password')), 1),
        _withDirectives(_createElementVNode("input", {
          type: _ctx.seePassword ? 'text' : 'password',
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.pwdErrors.pwd }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
          onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pwdErrors.pwd = !_ctx.password ? _ctx.$t('error.emptyField') : ''))
        }, null, 42, _hoisted_7), [
          [_vModelDynamic, _ctx.password]
        ]),
        (_ctx.pwdErrors.pwd)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.pwdErrors.pwd), 1))
          : _createCommentVNode("", true),
        (_ctx.password != '' && _ctx.password.match(/^[a-zA-Z0-9À-ÿ-.,_?!:;^*+=~<>()[\]{}|#$%&/@\\]{12,}$/gi))
          ? (_openBlock(), _createElementBlock("img", _hoisted_9))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "password__eye-btn",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.seePassword = !_ctx.seePassword))
        }, [
          (_ctx.seePassword)
            ? (_openBlock(), _createElementBlock("img", _hoisted_10))
            : (_openBlock(), _createElementBlock("img", _hoisted_11))
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('auth.password.check')), 1),
        _withDirectives(_createElementVNode("input", {
          type: _ctx.seePasswordCheck ? 'text' : 'password',
          class: _normalizeClass(["gnx-field-text", { _error: _ctx.pwdErrors.pwdCheck }]),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.passwordCheck) = $event)),
          onFocusout: _cache[4] || (_cache[4] = ($event: any) => (_ctx.pwdErrors.pwdCheck = !_ctx.passwordCheck ? _ctx.$t('error.emptyField') : ''))
        }, null, 42, _hoisted_14), [
          [_vModelDynamic, _ctx.passwordCheck]
        ]),
        (_ctx.pwdErrors.pwdCheck)
          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.pwdErrors.pwdCheck), 1))
          : _createCommentVNode("", true),
        (
						_ctx.passwordCheck === _ctx.password &&
						_ctx.passwordCheck != '' &&
						_ctx.password.match(/^[a-zA-Z0-9À-ÿ-.,_?!:;^*+=~<>()[\]{}|#$%&/@\\]{12,}$/gi)
					)
          ? (_openBlock(), _createElementBlock("img", _hoisted_16))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "password__eye-btn",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.seePasswordCheck = !_ctx.seePasswordCheck))
        }, [
          (_ctx.seePasswordCheck)
            ? (_openBlock(), _createElementBlock("img", _hoisted_17))
            : (_openBlock(), _createElementBlock("img", _hoisted_18))
        ])
      ]),
      _createElementVNode("button", {
        class: "password__submit gnx-btn _primary app-form__btn",
        disabled: _ctx.buttonLoading
      }, [
        _createTextVNode(_toDisplayString(!_ctx.buttonLoading
						? _ctx.$route.name == 'initialize-password'
							? _ctx.$t('auth.buttons.validAccount')
							: _ctx.$t('auth.buttons.reset')
						: '') + " ", 1),
        (_ctx.buttonLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20))
          : _createCommentVNode("", true)
      ], 8, _hoisted_19)
    ], 32)
  ]))
}