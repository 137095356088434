
import { Vue, Options } from 'vue-class-component';
import ConsumptionCardsGroup from '@/components/consumption/ConsumptionCardsGroup.vue';
import ConsumptionChart from '@/components/consumption/ConsumptionChart.vue';
import TicketComponent from '@/components/general/TicketComponent.vue';

@Options({
	components: {
		ConsumptionChart,
		ConsumptionCardsGroup,
		TicketComponent,
	},
})
export default class ConsumptionView extends Vue {}
