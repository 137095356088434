import { ExternalEnvironnement, ExternalUser } from '@/types/gonexa-users.type';
import { defineStore } from 'pinia';

export const useEnvironmentStore = defineStore('environment', {
	state: () => ({
		externalEnvironments: null as ExternalEnvironnement[] | null,

		externalUsers: null as ExternalUser[] | null,
	}),

	getters: {},

	actions: {
		defineExternalEnvironments(externalEnvironments: ExternalEnvironnement[]) {
			this.externalEnvironments = externalEnvironments;
		},

		defineExternalUsers(externalUsers: ExternalUser[]) {
			this.externalUsers = externalUsers;
		},

		deleteExternalEnvironment(id: string) {
			if (this.externalEnvironments != null) {
				const environementIndex = this.externalEnvironments.findIndex((env) => {
					return env.id == id;
				});
				if (environementIndex) this.externalEnvironments.splice(environementIndex, 1);
			}
		},

		securiseExternalEnvironment(id: string) {
			if (this.externalEnvironments != null) {
				const environementIndex = this.externalEnvironments.findIndex((env) => {
					return env.id == id;
				});
				if (environementIndex) this.externalEnvironments[environementIndex].isSecurised = true;
			}
		},
	},
});
