
import AuthSalesforceService from '@/services/auth-salesforce.service';
import { Vue } from 'vue-class-component';
export default class ConfigurationModal extends Vue {
	close() {
		this.$emit('close');
	}
	action() {
		this.$emit('action');
	}
	loginSalesforce(toSandbox: boolean) {
		AuthSalesforceService.loginToSalesforce(toSandbox, '');
	}
}
