
import { Vue, Options } from 'vue-class-component';

@Options({
	props: {
		errormessage: {
			type: String,
			default: 'error.webdocIssueWhenLoading',
		},
		is404: {
			type: Boolean,
			default: false,
		},
	},
})
export default class WebdocError extends Vue {
	currentLang = false;
	errormessage!: string;
	is404!: boolean;

	public changeLanguage() {
		this.$i18n.locale = this.currentLang ? 'fr' : 'en';
		localStorage.setItem('APP_LG', this.$i18n.locale);
		window.dispatchEvent(new Event('langChanged'));
	}
}
