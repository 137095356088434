
import { Vue } from 'vue-class-component';
import OrgInformationsService from '@/services/org-informations.service';
import { Organisation } from '@/types/salesforce-objects.type';
import { useToast } from 'vue-toastification';

export default class UserInfosCard extends Vue {
	organisation: Organisation | undefined = undefined;
	infoLoaded = false;
	toast = useToast();

	async mounted() {
		try {
			this.organisation = await OrgInformationsService.getOrgInfos();
			this.infoLoaded = true;
		} catch {
			this.organisation = {} as Organisation;
			this.infoLoaded = true;
		}
	}
}
