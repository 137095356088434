
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '@/stores/authentication.store';
import AuthGonexaService from '@/services/auth-gonexa.service';

export default class NavigationComponent extends Vue {
	toast = useToast();
	authStore = useAuthStore();
	navbarState = true;

	async mounted() {
		const navbarStateLS = localStorage.getItem('APP_NB');
		if (navbarStateLS) this.navbarState = navbarStateLS == 'true' ? true : false;
		window.addEventListener('resize', this.closeIfSmallScreen);
	}

	/**
	 * Méthode pour gérer l'ouverture et la fermeture de la barre de navigation
	 * Et ajout en localstorage de cet état, pour qu'il soit conservé même si la page est rechargée
	 */
	manageNavbar() {
		this.navbarState = !this.navbarState;
		localStorage.setItem('APP_NB', this.navbarState.toString());
	}

	/**
	 * Méthode pour déconnecter l'utilisateur
	 */
	disconnectUser() {
		const localUserToken = localStorage.getItem('APP_U');
		if (localUserToken) {
			AuthGonexaService.disconnectUser(localUserToken);
		}
		localStorage.removeItem('APP_U');
		localStorage.removeItem('APP_LOG_AS');
		this.authStore.emptyStore();
		this.authStore.emitDisconnectToast = true;
		this.$router.push({ name: 'login' });
	}

	closeIfSmallScreen() {
		if (window.innerWidth <= 768 && this.navbarState) {
			this.navbarState = !this.navbarState;
			localStorage.setItem('APP_NB', this.navbarState.toString());
		}
	}
}
