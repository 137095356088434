
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import OrgContractService from '@/services/org-contract.service';
import { Invoice } from '@/types/salesforce-objects.type';

export default class BillingCardsGroup extends Vue {
	toast = useToast();
	invoices: Invoice[] | undefined = undefined;
	lastInvoice: Invoice | undefined = undefined;
	infoLoaded = false;
	globalStatus = '';
	globalStatusIcon = '';

	async mounted() {
		try {
			this.invoices = await OrgContractService.getAllInvoices();
			this.defineGlobalStatus();
			this.lastInvoice = this.invoices[this.invoices.length - 1];
			this.infoLoaded = true;
			window.addEventListener('langChanged', () => {
				this.defineGlobalStatus();
			});
		} catch {
			this.infoLoaded = true;
		}
	}

	beforeDestroy() {
		window.removeEventListener('langChanged', () => {
			this.defineGlobalStatus();
		});
	}

	/**
	 * Définit le texte et l'icone correspondant à l'état global des factures
	 */
	defineGlobalStatus() {
		if (this.invoices != undefined) {
			const invoicesNb = this.invoices?.length;
			let index = 0;
			let isInPayment = false;
			let isLate = false;

			while (index < invoicesNb && !isLate) {
				const indexStage = this.invoices[index].gnx__Stage__c.toLocaleLowerCase();
				let indexDate = this.invoices[index].payment_due_date__c;

				if (this.compareDates(indexDate) == -1 && indexStage != 'paid') isLate = true;
				else if (indexStage == 'issued') isInPayment = true;
				index++;
			}

			if (isLate) {
				this.globalStatus = this.$t('billing.late');
				this.globalStatusIcon = require('@/assets/svg/warning_red.svg');
			} else if (isInPayment) {
				this.globalStatus = this.$t('billing.toPay');
				this.globalStatusIcon = require('@/assets/svg/alarm_yellow.svg');
			} else {
				this.globalStatus = this.$t('billing.upToDate');
				this.globalStatusIcon = require('@/assets/svg/check_green.svg');
			}
		}
	}

	compareDates(dueDateStr: string): number {
		if (dueDateStr.includes('T')) dueDateStr = dueDateStr.slice(0, 10);
		const dueDateParts: any = dueDateStr.split('-');
		let dueDate = new Date(+dueDateParts[0], dueDateParts[1] - 1, +dueDateParts[2]);
		dueDate.setHours(0, 0, 0, 0);

		let currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);

		if (dueDate < currentDate) return -1;
		else return 0;
	}

	getFormattedDate(dateString: string) {
		try {
			if (dateString != undefined) {
				// Si c'est un format DATETIME, je ne garde que la partie DATE
				if (dateString.includes('T')) dateString = dateString.slice(0, 10);
				const dateParts: any = dateString.split('-');
				const date = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

				// Récupère le jour, le mois et l'année de la date
				const day = date.getDate().toString().padStart(2, '0');
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const year = date.getFullYear();

				if (this.$i18n.locale == 'fr') {
					// Formate la date en DD/MM/YYYY si l'App est en français
					return `${day}/${month}/${year}`;
				} else {
					// Formate la date en DD/MM/YYYY sinon
					return `${day}/${month}/${year}`;
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
}
