import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-title-1 --shrinkable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConsumptionCardsGroup = _resolveComponent("ConsumptionCardsGroup")!
  const _component_ConsumptionChart = _resolveComponent("ConsumptionChart")!
  const _component_TicketComponent = _resolveComponent("TicketComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('app.consumption')), 1),
    _createVNode(_component_ConsumptionCardsGroup),
    _createVNode(_component_ConsumptionChart),
    _createVNode(_component_TicketComponent)
  ], 64))
}