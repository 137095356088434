
import { Vue, Options } from 'vue-class-component';

@Options({
	props: {
		title: String,
		subtitle: String,
		icon: String,
		buttonIcon: String,
		buttonText: String,
		isValidation: {
			type: Boolean,
			default: false,
		},
	},
})
export default class WebdocPopup extends Vue {
	currentLang = false;

	title!: string;
	subtitle!: string;
	icon!: string;
	buttonIcon!: string;
	buttonText!: string;
	isValidation!: boolean;
	whiteImg = require(`@/assets/svg/check_white_new.svg`);

	public changeLanguage() {
		this.$i18n.locale = this.currentLang ? 'fr' : 'en';
		localStorage.setItem('APP_LG', this.$i18n.locale);
		window.dispatchEvent(new Event('langChanged'));
	}

	close() {
		this.$emit('close');
	}

	action() {
		this.$emit('action');
	}
	unhover(event: Event) {
		const element = event.target as HTMLElement;

		if (element.firstChild) (element.firstChild as HTMLElement).setAttribute('src', this.buttonIcon);
	}

	hover(event: Event) {
		const element = event.target as HTMLElement;
		if (element.firstChild) (element.firstChild as HTMLElement).setAttribute('src', this.whiteImg);
	}
}
