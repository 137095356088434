
import { Vue } from 'vue-class-component';
import { useAuthStore } from '@/stores/authentication.store';

export default class WarningConnection extends Vue {
	authStore = useAuthStore();
	isOwnerFree = false;
	isOwnerVerifInProgress = false;
	isOwnerVerified = false;
	isOwnerBlocked = false;
	isUser = false;

	mounted(): void {
		const roleName = this.authStore.roleName?.toLowerCase();
		const companyStatus = this.authStore.companyStatus?.toLowerCase();

		if (roleName === 'owner') {
			this.isOwnerFree = companyStatus === 'free';
			this.isOwnerVerifInProgress = companyStatus === 'verificationinprogress';
			this.isOwnerVerified = companyStatus === 'verified';
			this.isOwnerBlocked = companyStatus === 'blocked';
		} else {
			this.isUser = true;
		}
	}
}
