import axios, { AxiosError } from 'axios';
import { useToast } from 'vue-toastification';
import { Vue } from 'vue-class-component';
import { useAuthStore } from '@/stores/authentication.store';
import { EditUser, GonexaUser } from '@/types/gonexa-users.type';
import { useSalesforceStore } from '@/stores/salesforce.store';

// Classe contenant toutes les méthodes d'appels API liés à l'authentification du compte Gonexa

export default class UserService {
	static toast = useToast();
	static authStore: ReturnType<typeof useAuthStore>;
	static sfStore: ReturnType<typeof useSalesforceStore>;

	static async getCompanyUsers(): Promise<GonexaUser[]> {
		this.sfStore = useSalesforceStore();
		if (this.sfStore.companyUsers) return this.sfStore.companyUsers;
		this.authStore = useAuthStore();

		return await axios
			.get(`${process.env.VUE_APP_APP_API_URI}/api/company/users`, {
				headers: {
					Authorization: `Bearer ${this.authStore.userToken}`,
				},
			})
			.then(({ data }) => {
				this.sfStore.companyUsers = data;
				return data;
			})
			.catch((error: AxiosError) => {
				console.error('👨‍👦‍👦 Erreur dans la récupération de tous les Users liés à la Company.');
				console.error(error);

				switch (error.response?.data) {
					case 'IsNotOwner': {
						this.toast.info(Vue.prototype.$t('error.notOwner'));
						break;
					}
					case 'CompanyNotVerified': {
						this.toast.info(Vue.prototype.$t('error.companyNotVerified'));
						break;
					}
					default: {
						this.toast.error(Vue.prototype.$t('error.getUsers'));
						break;
					}
				}
			});
	}

	static async deleteCompanyUser(user: GonexaUser) {
		this.authStore = useAuthStore();
		this.sfStore = useSalesforceStore();

		return await axios
			.delete(`${process.env.VUE_APP_APP_API_URI}/api/company/user/${user.username}`, {
				headers: { Authorization: `Bearer ${this.authStore.userToken}` },
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error("👨‍👦‍👦 Erreur la suppression d'un User lié à la Company.");
				console.error(error);

				throw new Error();
			});
	}

	static async inviteNewUser(newUser: GonexaUser) {
		this.authStore = useAuthStore();
		this.sfStore = useSalesforceStore();

		return await axios
			.post(
				`${process.env.VUE_APP_APP_API_URI}/api/user/invite`,
				{
					username: newUser.username,
					firstName: newUser.firstName,
					lastName: newUser.lastName,
					email: newUser.email,
					roleName: newUser.roleName,
				},
				{
					headers: { Authorization: `Bearer ${this.authStore.userToken}` },
				}
			)
			.then(({ data }) => {
				this.sfStore.addCompanyUser(newUser);
				return data;
			})
			.catch((error: AxiosError) => {
				console.error("👨‍👦‍👦 Erreur dans l'invitation d'un nouvel User.");
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	static async updateUser(edituser: EditUser) {
		this.authStore = useAuthStore();

		return await axios
			.patch(`${process.env.VUE_APP_APP_API_URI}/api/user`, edituser, {
				headers: { Authorization: `Bearer ${this.authStore.userToken}` },
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error("👨‍👦‍👦 Erreur dans la mise à jour du mot de passe de l'utilisateur.");
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	static async updateUserEmail(emailToken: string) {
		return await axios
			.patch(`${process.env.VUE_APP_APP_API_URI}/api/user/email`, '', {
				headers: { Authorization: `Bearer ${emailToken}` },
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error("👨‍👦‍👦 Erreur dans la mise à jour du mail de l'utilisateur.");
				console.error(error);

				if (typeof error.response?.data === 'string') throw new Error(error.response.data);
				else throw new Error();
			});
	}

	static async getUserId(username: string): Promise<string> {
		this.authStore = useAuthStore();

		return await axios
			.get(`${process.env.VUE_APP_APP_API_URI}/api/user/${username}/id`, {
				headers: {
					Authorization: `Bearer ${this.authStore.userToken}`,
				},
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error: AxiosError) => {
				console.error("👨‍👦‍👦 Erreur dans la récupération de l'id d'un utilisateur.");
				console.error(error);

				throw new Error();
			});
	}
}
