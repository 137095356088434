
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useAuthStore } from './stores/authentication.store';
import NavigationComponent from './components/general/NavigationComponent.vue';
import { Vue } from 'vue-class-component';
import LangChoice from '@/components/general/LangChoice.vue';

export default defineComponent({
	name: 'App',

	components: {
		NavigationComponent,
		LangChoice,
	},

	data() {
		return {
			toast: useToast(),
			authStore: useAuthStore(),
			loggedAs: false,
		};
	},

	async mounted() {
		// Configuration de l'accessibilité des traductions dans les services
		Vue.prototype.$t = this.$t;

		// Configuration de l'initialisation de la langue de l'App
		const lang = localStorage.getItem('APP_LG');
		if (!lang) localStorage.setItem('APP_LG', 'fr');
		this.$i18n.locale = lang ? (lang == 'en' ? 'en' : 'fr') : 'fr';

		this.$nextTick(() => {
			let loggedAs = localStorage.getItem('APP_LOG_AS');
			if (loggedAs && loggedAs == 'true') this.loggedAs = true;
			else {
				setTimeout(() => {
					loggedAs = localStorage.getItem('APP_LOG_AS');
					if (loggedAs && loggedAs == 'true') this.loggedAs = true;
				}, 1000);
			}
		});
	},
});
