import { defineStore } from 'pinia';
import { LoggedUserData } from '@/types/gonexa-auth.type';

export const useAuthStore = defineStore('authentication', {
	state: () => ({
		username: null as string | null,
		firstname: null as string | null,
		lastname: null as string | null,
		email: null as string | null,
		roleName: null as string | null,
		accountStatus: null as string | null,
		companyStatus: null as string | null,
		createdDate: null as string | null,
		userToken: null as string | null,

		emitDisconnectToast: false,
	}),

	// Computed properties
	getters: {},

	// Methods
	actions: {
		async logUser(user: LoggedUserData) {
			this.username = user.username;
			this.email = user.email;
			this.firstname = user.firstName;
			this.lastname = user.lastName;
			this.roleName = user.roleName;
			this.accountStatus = user.accountStatus;
			this.companyStatus = user.companyStatus;
			this.createdDate = user.createdDate;
			this.userToken = user.userToken;
		},

		updateUserToken(userToken: string) {
			this.userToken = userToken;
		},

		dataToString(): string {
			return `username : ${this.username}\nfirstname : ${this.firstname}\nlastname : ${this.lastname}\nemail : ${this.email}\nuserToken : ${this.userToken}\ncreatedDate : ${this.createdDate}\nroleName : ${this.roleName}\naccountStatus : ${this.accountStatus}\ncompanyStatus : ${this.companyStatus}\ncreatedDate : ${this.createdDate}\nuserToken : ${this.userToken}\n`;
		},

		emptyStore(): void {
			this.userToken = null;
		},
	},
});
