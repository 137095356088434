import App from './App.vue';
import { createApp, h } from 'vue';
import router from './router';
import { createPinia } from 'pinia';
import Toast, { TYPE } from 'vue-toastification';
import i18n from './i18n';
import {
	ToastButtonError,
	ToastInfoIcon,
	ToastSuccessIcon,
	ToastWarningIcon,
	ToastErrorIcon,
	ToastButtonSuccess,
	ToastButtonInfo,
	ToastButtonWarning,
} from './toast';
// import 'vue-toastification/dist/index.css';

// CONFIG STORES PINIA
const pinia = createPinia();

// CONFIG TOASTS
const toastOptions = {
	transition: 'Vue-Toastification__fade',
	maxToasts: 3,
	newestOnTop: true,
	position: 'bottom-center',
	timeout: 10000,
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: false,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: ToastButtonSuccess,
	icon: true,
	rtl: false,

	containerClassName: 'app-toast',

	toastDefaults: {
		// ToastOptions object for each type of toast
		[TYPE.ERROR]: {
			closeButton: ToastButtonError,
			icon: ToastErrorIcon,
			toastClassName: 'toast-error',
		},
		[TYPE.SUCCESS]: {
			closeButton: ToastButtonSuccess,
			icon: ToastSuccessIcon,
			toastClassName: 'toast-success',
		},
		[TYPE.INFO]: {
			closeButton: ToastButtonInfo,
			icon: ToastInfoIcon,
			toastClassName: 'toast-info',
		},
		[TYPE.WARNING]: {
			closeButton: ToastButtonWarning,
			icon: ToastWarningIcon,
			toastClassName: 'toast-warning',
		},
	},
};

createApp(App)
	.use(i18n)
	.use(router)
	.use(pinia) // Stores
	.use(Toast, toastOptions) // Toasts de notifications
	.mount('#app');
