import { useToast } from 'vue-toastification';
import CalloutAppApiService from './callout-app-api.service';

/**
 * Gestion de la liaison du compte Gonexa avec l'environnement Salesforce
 */
export default class AuthSalesforceService {
	static toast = useToast();

	/**
	 * Méthode qui permet à l'utilisateur de se connecter à un compte Salesforce, puis d'être redirigé sur l'App
	 * TODO: Description des params et retour
	 */
	static async loginToSalesforce(toSandbox: boolean, instanceUrl: string) {
		const salesforceState = {
			orgType: toSandbox ? 'test' : 'login',
			specificDomain: '', // Si nom de domaine spécifique pour le login, par exemple : gonexa.my.salesforce.com
		};
		let domain = instanceUrl;
		if (!domain) {
			domain = toSandbox ? process.env.VUE_APP_TEST_URI : process.env.VUE_APP_PROD_URI;
		}
		const salesforceOAuthUrl = `${domain}/services/oauth2/authorize?prompt=login&response_type=code&client_id=${
			process.env.VUE_APP_CLIENT_ID
		}&redirect_uri=${location.origin}/callback&scope=id+api+refresh_token+offline_access&state=${JSON.stringify(
			salesforceState
		)}`;
		window.location.replace(salesforceOAuthUrl);
	}

	/**
	 * Méthode qui permet à l'utilisateur
	 * TODO: Description des params et retour
	 */
	static async authToSalesforce(salesforceCode: string, orgType: string, specificDomain: string) {
		const requestBody = {
			salesforceCode: salesforceCode,
			orgType: orgType,
			specificDomain: specificDomain,
		};

		const uri: string = process.env.VUE_APP_APP_API_URI;

		return await CalloutAppApiService.calloutAsync('post', `${uri}/api/salesforce/auth`, requestBody);
	}
}
