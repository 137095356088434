
import { Vue } from 'vue-class-component';

export default class UserDeleteModal extends Vue {
	close() {
		this.$emit('close');
	}

	action() {
		this.$emit('action');
	}
}
