
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import AuthGonexaService from '@/services/auth-gonexa.service';
import { useAuthStore } from '@/stores/authentication.store';
import { LoggedUserData } from '@/types/gonexa-auth.type';

export default class LoginAs extends Vue {
	toast = useToast();
	authStore = useAuthStore();
	loginLoading = false;

	async mounted() {
		const username = this.$route.params.username.toString();
		const token = this.$route.params.token.toString();

		if (username && token) {
			await this.loginUser(username as string, token);
		} else {
			this.toast.error(this.$t('error.notAuthorized'));
			this.$router.push({ name: 'home' });
		}
	}

	/**
	 * Méthode qui déclenche la connexion à un compte Gonexa en base si les données sont valides
	 */
	async loginUser(username: string, token: string) {
		this.loginLoading = true;

		AuthGonexaService.loginAs(username, token)
			.then(async (response: LoggedUserData) => {
				this.loginLoading = false;
				localStorage.setItem('APP_U', response.userToken);
				localStorage.setItem('APP_LOG_AS', 'true');
				this.authStore.logUser(response);
				this.$router.push({ name: 'home' });
			})
			.catch((error: Error) => {
				this.loginLoading = false;

				switch (error.message) {
					case 'UsernameNotExisting': {
						this.toast.error(this.$t('error.auth.unknownUsername'));
						break;
					}
					case 'RegistrationNotSucceeded':
						this.toast.error(this.$t('error.auth.registrationNotSucceeded'));
						break;
					case 'TokenInvalid': {
						this.toast.error(this.$t('error.notAuthorized'));
						break;
					}
					default: {
						this.toast.error(this.$t('error.auth.login'));
						break;
					}
				}
				this.$router.push({ name: 'home' });
			});
	}
}
