import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      class: "gnx-toggle-btn",
      type: "checkbox",
      id: "change-lang",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeLanguage && _ctx.changeLanguage(...args))),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentLang) = $event))
    }, null, 512), [
      [_vModelCheckbox, _ctx.currentLang]
    ]),
    _createElementVNode("label", {
      class: _normalizeClass(["gnx-toggle-txt dashboard__lang", !_ctx.currentLang ? 'fr' : 'en']),
      for: "change-lang"
    }, null, 2)
  ], 64))
}