
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '@/stores/authentication.store';
import TicketComponent from '@/components/general/TicketComponent.vue';

import EnvironmentsService from '@/services/environments.service';
import DocumentTemplateService from '@/services/document-template.service';
import { ExternalEnvironnement, ExternalUser } from '@/types/gonexa-users.type';
import { DocumentTemplate } from '@/types/salesforce-objects.type';

@Options({
	components: {
		TicketComponent,
	},
})
export default class DeploymentView extends Vue {
	toast = useToast();
	authStore = useAuthStore();

	environmentLoaded = false;
	documentsLoading = false;
	deploymentLoading = false;

	showListSource = false;
	showListTarget = false;

	externalEnvironnements: ExternalEnvironnement[] = [];
	externalEnvironnementsTarget: ExternalEnvironnement[] = [];
	selectedSource = '';
	selectedTarget = '';
	externalUsers: ExternalUser[] = [];

	documents: DocumentTemplate[] = [];
	selectedDocuments: string[] = [];
	searchText = '';

	isTemplateSorted = 1;
	isFileSorted = 0;

	async mounted() {
		// Récupération de tous les External Environments et Users associés à l'utilisateur
		this.externalEnvironnements = await EnvironmentsService.getExternalEnvironments();
		this.externalUsers = await EnvironmentsService.getExternalUsers();

		this.externalEnvironnements = this.externalEnvironnements.filter((x) =>
			this.externalUsers.some((y) => y.externalEnvironmentId == x.id)
		);
		this.environmentLoaded = true;
	}

	/**
	 * Recherche un docment template par son nom, son nom de fichier, ou son extension
	 */
	searchedDocumentList(): DocumentTemplate[] {
		return this.documents.filter(
			(document) =>
				document.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
				(document.fileName && document.fileName.toLowerCase().includes(this.searchText.toLowerCase())) ||
				(document.extension &&
					this.getFormatText(document.extension).toLowerCase().includes(this.searchText.toLowerCase()))
		);
	}

	/**
	 * Charge la liste des document templates
	 */
	async selectEnvironment(env: ExternalEnvironnement, isSource: boolean) {
		this.showListSource = false;
		this.showListTarget = false;

		if (isSource) {
			this.selectedSource = env.orgName;

			this.documentsLoading = true;

			const externalEnvironmentId: string | undefined = this.externalEnvironnements.find(
				(x) => x.orgName == this.selectedSource
			)?.externalId;

			if (externalEnvironmentId) {
				this.documents = (
					await DocumentTemplateService.getAllDocumentTemplatesInfo(externalEnvironmentId)
				)?.sort((x, y) => x.name.localeCompare(y.name));
				this.documentsLoading = false;

				this.externalEnvironnementsTarget = this.externalEnvironnements.filter(
					(x) => x.externalId != externalEnvironmentId
				);
				this.selectedTarget = '';
			}

			this.selectedDocuments = [];
			this.documentsLoading = false;
		} else {
			this.selectedTarget = env.orgName;
		}
	}

	/**
	 * Initialise le document séléctionné
	 */
	selectDocument(event: Event, document: DocumentTemplate) {
		const isCheckboxChecked = (event.target as HTMLInputElement).checked;

		if (isCheckboxChecked) {
			if (!this.selectedDocuments.includes(document.externalId)) {
				this.selectedDocuments.push(document.externalId);
			}
		} else {
			if (this.selectedDocuments.includes(document.externalId)) {
				const index = this.selectedDocuments.indexOf(document.externalId);
				this.selectedDocuments.splice(index, 1);
			}
		}
	}

	/**
	 * Sélectionne tous les documents
	 */
	selectAllDocument() {
		if (this.documents.filter((element) => element.azureId != undefined).length == this.selectedDocuments.length) {
			this.selectedDocuments = [];
		} else {
			this.selectedDocuments = [];
			this.documents.forEach((element) => {
				if (element.azureId) this.selectedDocuments.push(element.externalId);
			});
		}
	}

	/**
	 * Deploie le ou les documents sélectionnés
	 */
	async deployDocuments() {
		if (this.selectedSource && this.selectedTarget && this.selectedDocuments.length > 0) {
			this.toast.info(this.$t('deployment.title') + ' ...', { showCloseButtonOnHover: true });

			const sourceOrg: ExternalEnvironnement | undefined = this.externalEnvironnements.find(
				(x) => x.orgName == this.selectedSource
			);
			const targetOrg: ExternalEnvironnement | undefined = this.externalEnvironnements.find(
				(x) => x.orgName == this.selectedTarget
			);

			if (sourceOrg && targetOrg) {
				this.deploymentLoading = true;

				await DocumentTemplateService.deployDocumentTemplates(
					sourceOrg.instanceUrl,
					targetOrg.instanceUrl,
					this.selectedDocuments,
					targetOrg.typeOrg,
					targetOrg.externalId
				);
				this.deploymentLoading = false;
			}
		} else {
			if (!this.selectedSource) this.toast.info(this.$t('deployment.noSourceSelected'));
			else if (!this.selectedTarget) this.toast.info(this.$t('deployment.noDestinationSelected'));
			else if (this.selectedDocuments.length <= 0) this.toast.info(this.$t('deployment.noModelSelected'));
		}
	}

	/**
	 * SOURCE DE CETTE FONCTION : https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
	 * Format octets as human-readable text.
	 *
	 * @param octets Number of octets.
	 * @param si True to use metric (SI) units, aka powers of 1000. False to use
	 *           binary (IEC), aka powers of 1024.
	 * @param dp Number of decimal places to display.
	 *
	 * @return Formatted string.
	 */
	humanFileSize(octets: number, si = false, dp = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(octets) < thresh) {
			return octets + ' o';
		}

		const units = si
			? ['Kio', 'Mio', 'Gio', 'Tio', 'Pio', 'Eio', 'Zio', 'Yi']
			: ['Ko', 'Mo', 'Go', 'TB', 'Po', 'Eo', 'Zo', 'Yo'];
		let u = -1;
		const r = 10 ** dp;

		do {
			octets /= thresh;
			++u;
		} while (Math.round(Math.abs(octets) * r) / r >= thresh && u < units.length - 1);

		return octets.toFixed(dp).replace('.', ',') + ' ' + units[u];
	}

	/**
	 * Retourne le nom de l'image correspondant au format du fichier dans le dossier png
	 * @param format format du fichier
	 */
	getFormatIcon(format: string): string {
		switch (format) {
			case 'docx':
				return 'logo_word';
			case 'xlsx':
				return 'logo_excel';
			case 'pptx':
				return 'logo_powerpoint';
			case 'pdf':
				return 'logo_pdf';
		}
		return 'insert_drive_file';
	}

	/**
	 * Retourne le nom du format du fichier
	 * @param format format du fichier
	 */
	getFormatText(format: string): string {
		switch (format) {
			case 'docx':
				return 'Word';
			case 'xlsx':
				return 'Excel';
			case 'pptx':
				return 'PowerPoint';
			case 'pdf':
				return 'PDF';
		}
		return this.$t('deployment.unknownFormat');
	}

	sortTableColumn(type: string) {
		let newSortedList = [];

		if (type == 'template') {
			// Si 0, alors pas trié, donc on tri en croissant
			// Si 1, trié en croissants, donc on trie en décroissant
			// Si -1, trié en décroissant, dont on trie en croissant

			newSortedList =
				this.isTemplateSorted == 1
					? this.documents.sort((x, y) => x.name.localeCompare(y.name)).reverse()
					: this.documents.sort((x, y) => x.name.localeCompare(y.name));

			// Suite au tri, on met à jour la variable pour qu'elle soit cohérente
			this.isTemplateSorted = this.isTemplateSorted == 1 ? -1 : 1;

			// Et on remet l'autre colonne non triées à 0
			this.isFileSorted = 0;
		} else {
			newSortedList =
				this.isFileSorted == 1
					? this.documents.sort((x, y) => x.fileName.localeCompare(y.fileName)).reverse()
					: this.documents.sort((x, y) => x.fileName.localeCompare(y.fileName));

			this.isFileSorted = this.isFileSorted == 1 ? -1 : 1;
			this.isTemplateSorted = 0;
		}

		this.documents = newSortedList;
	}
}
