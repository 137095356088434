
import { Vue, Options } from 'vue-class-component';
import TicketComponent from '@/components/general/TicketComponent.vue';
import AuthSalesforceService from '@/services/auth-salesforce.service';
import EnvironmentsService from '@/services/environments.service';
import { ExternalEnvironnement, ExternalUser } from '@/types/gonexa-users.type';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '@/stores/authentication.store';
import WorkspaceModal from './WorkspaceModal.vue';
import WarningConnection from '../general/WarningConnection.vue';

@Options({
	components: {
		TicketComponent,
		WorkspaceModal,
		WarningConnection,
	},
})
export default class WorkpaceView extends Vue {
	toast = useToast();
	authStore = useAuthStore();

	externalEnvironnements: ExternalEnvironnement[] | undefined = undefined;
	externalUsers: ExternalUser[] | undefined = undefined;
	prodEnvironment: ExternalEnvironnement | undefined = undefined;
	sandboxNb = 0;
	prodExists = false;
	isNameSorted = 1;
	isExternalEnvironmentsLoaded = false;

	externalEnvironmentToDelete = '';
	isModalOpen = false;
	loadingEnvironmentsToSecurise: {
		[index: string]: boolean;
	} = {};

	async mounted() {
		try {
			// Chargement des External Environments et Users
			this.externalEnvironnements = (await EnvironmentsService.getExternalEnvironments())?.sort((x, y) =>
				x.orgName.localeCompare(y.orgName)
			);
			this.externalUsers = await EnvironmentsService.getExternalUsers();

			this.checkIfProdExists();
			this.sandboxNb = this.externalEnvironnements.filter(
				(env) => env.isConnected && env.typeOrg.toLowerCase() == 'sandbox'
			).length;
			this.externalEnvironnements.forEach((env) => {
				env.isConnected = this.externalUsers?.some((user) => user.externalEnvironmentId === env.id) ?? false;
			});
			this.isExternalEnvironmentsLoaded = true;
		} catch (error) {
			this.toast.error(this.$t('error.environment.null'));
		}
	}

	checkIfProdExists(): void {
		if (
			this.externalEnvironnements &&
			this.externalEnvironnements.filter((env) => env.typeOrg.toLocaleLowerCase() == 'prod').length > 0
		) {
			this.prodEnvironment = this.externalEnvironnements.filter(
				(env) => env.typeOrg.toLocaleLowerCase() == 'prod'
			)[0];
			this.prodExists = true;
		} else this.prodExists = false;
	}

	loginSalesforce(toSandbox: boolean) {
		AuthSalesforceService.loginToSalesforce(toSandbox, '');
	}

	loginSalesforceWithUrl(toSandbox: boolean, instanceUrl: string) {
		AuthSalesforceService.loginToSalesforce(toSandbox, instanceUrl);
	}

	openDeleteModal(id: string) {
		this.externalEnvironmentToDelete = id;
		this.isModalOpen = true;
	}

	closeModal() {
		this.isModalOpen = false;
	}

	async deleteExternalEnvironment(id: string) {
		if (id) {
			await EnvironmentsService.deleteExternalUser(id);

			// Rechargement des External Environments suite à la suppression d'un ExternalUser
			this.externalEnvironnements = await EnvironmentsService.getExternalEnvironments();
			this.sandboxNb = this.externalEnvironnements.filter(
				(env) => env.isConnected && env.typeOrg.toLowerCase() == 'sandbox'
			).length;
		}
		this.closeModal();
	}

	async secureOrg(id: string) {
		this.loadingEnvironmentsToSecurise[id] = true;

		const isSuccess = await EnvironmentsService.securiseExternalEnvironment(id);

		if (isSuccess == true && this.externalEnvironnements) {
			const indexEnvironment = this.externalEnvironnements.findIndex((env) => env.id == id);
			this.externalEnvironnements[indexEnvironment].isSecurised = true;
		}

		this.loadingEnvironmentsToSecurise[id] = false;
	}

	sortTableColumn() {
		// Si 0, alors pas trié, donc on tri en croissant
		// Si 1, trié en croissants, donc on trie en décroissant
		// Si -1, trié en décroissant, dont on trie en croissant

		if (this.externalEnvironnements != undefined) {
			let newSortedList = [];

			newSortedList =
				this.isNameSorted == 1
					? this.externalEnvironnements.sort((x, y) => x.orgName.localeCompare(y.orgName)).reverse()
					: this.externalEnvironnements.sort((x, y) => x.orgName.localeCompare(y.orgName));

			// Suite au tri, on met à jour la variable pour qu'elle soit cohérente
			this.isNameSorted = this.isNameSorted == 1 ? -1 : 1;

			this.externalEnvironnements = newSortedList;
		}
	}
}
