
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { LoginUserData, LoggedUserData } from '@/types/gonexa-auth.type';
import AuthGonexaService from '@/services/auth-gonexa.service';
import { useAuthStore } from '@/stores/authentication.store';

interface LoginInputs {
	username: string;
	encryptedPassword: string;
}

export default class LoginForm extends Vue {
	toast = useToast();
	authStore = useAuthStore();
	seePassword = false;
	loginLoading = false;

	gnxUser = {} as LoginUserData; // Objet qui contient les donénes saisies par l'utilisateur
	inputErrors = {} as LoginInputs; // Objet qui contient les messages erreurs associés à chaque champ de saisie

	mounted() {
		if (this.authStore.emitDisconnectToast) {
			this.toast.info(this.$t('auth.disconnected'));
			this.authStore.emitDisconnectToast = false;
		}
	}

	/**
	 * Méthode qui déclenche la connexion à un compte Gonexa en base si les données sont valides
	 */
	async loginUser() {
		if (this.checkInputErrors()) {
			this.loginLoading = true;
			const encryptedPassword = btoa(this.gnxUser.password);

			AuthGonexaService.login(this.gnxUser.username, encryptedPassword)
				.then(async (response: LoggedUserData) => {
					this.loginLoading = false;
					localStorage.setItem('APP_U', response.userToken);
					this.authStore.logUser(response);

					// Check si l'utilisateur souhaitait accéder à une autre page avant sa connection
					const nextPage = localStorage.getItem('APP_NEXT_LOGIN');

					if (nextPage) {
						// Si oui, clean du LocalStorage et redirection vers cette page
						localStorage.removeItem('APP_NEXT_LOGIN');
						this.$router.push({ name: nextPage });
					} else {
						// Sinon, redirection vers la page d'accueil classique
						this.$router.push({ name: 'home' });
					}
				})
				.catch((error: Error) => {
					this.loginLoading = false;

					switch (error.message) {
						case 'UsernameNotExisting': {
							this.inputErrors.username = this.$t('error.auth.unknownUsername');
							break;
						}
						case 'RegistrationNotSucceeded':
							this.toast.error(this.$t('error.auth.registrationNotSucceeded'));
							break;
						case 'WrongPassword': {
							this.inputErrors.encryptedPassword = this.$t('error.auth.wrongPassword');
							break;
						}
						default: {
							this.toast.error(this.$t('error.auth.login'));
							break;
						}
					}
				});
		}
	}

	checkInputErrors(): boolean {
		let validateLogin = true;

		if (!this.gnxUser.username) {
			this.inputErrors.username = this.$t('error.emptyField');
			validateLogin = false;
		}

		if (!this.gnxUser.password) {
			this.inputErrors.encryptedPassword = this.$t('error.emptyField');
			validateLogin = false;
		}

		return validateLogin;
	}
}
