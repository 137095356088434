
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '@/stores/authentication.store';
import AuthGonexaService from '@/services/auth-gonexa.service';
import AuthSalesforceService from '@/services/auth-salesforce.service';

export default class CallbackSalesforce extends Vue {
	toast = useToast();
	authStore = useAuthStore();

	async mounted() {
		const salesforceCode = new URLSearchParams(window.location.search).get('code');
		const encodedState = new URLSearchParams(window.location.search).get('state');
		const salesforceState = encodedState == null ? '' : JSON.parse(decodeURI(encodedState));

		if (salesforceCode && salesforceState) {
			await AuthSalesforceService.authToSalesforce(
				salesforceCode,
				salesforceState.orgType,
				salesforceState.specificDomain
			)
				.then(async () => {
					await AuthGonexaService.getCompanyStatus().then((newStatus) => {
						this.authStore.companyStatus = newStatus;
					});
				})
				.catch((error) => {
					switch (error) {
						case 'access_denied':
							this.toast.error(this.$t('error.salesforce.accessDenied'));
							break;
						case 'inactive_org':
							this.toast.error(this.$t('error.salesforce.inactiveOrg'));
							break;
						case 'inactive_user':
							this.toast.error(this.$t('error.salesforce.inactiveUser'));
							break;
						case 'invalid_app_acess':
							this.toast.error(this.$t('error.salesforce.noAccess'));
							break;
						case 'NO_ACCESS':
							this.toast.error(this.$t('error.salesforce.noUser'));
							break;
						case 'server_error':
							this.toast.error(this.$t('error.salesforce.server'));
							break;
						default:
							console.error(this.$t('consoleError.salesforceAuth'));
							console.error(error);

							this.toast.error(this.$t('error.salesforce.auth'));
							break;
					}
				})
				.finally(async () => {
					this.$router.push('workspaces');
				});
		}
	}
}
