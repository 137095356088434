import { Webdoc, WebdocAction } from '@/types/webdoc.type';
import axios from 'axios';
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

export default class WebdocService {
	static toast = useToast();

	static async getWebdoc(token: string): Promise<Webdoc> {
		return await axios
			.get(`${process.env.VUE_APP_APP_API_URI}/api/webdoc/${token}`)
			.then(({ data }) => {
				return data;
			})
			.catch((error: Error) => {
				console.error(error);
				throw error;
			});
	}

	static async getBlobFromContentId(webdocToken: string, contentDocumentId: string): Promise<string> {
		try {
			const htmlContent = await axios.post(`${process.env.VUE_APP_APP_API_URI}/api/webdoc/html`, {
				webdocToken: webdocToken,
				contentDocId: contentDocumentId,
			});

			return htmlContent.data.content;
		} catch (error) {
			console.error(
				'📄 Erreur lors de la récupération du fichier Salesforce ' + contentDocumentId + ' : ' + error
			);
			console.error(error);
			// Traitez l'erreur selon vos besoins
			throw error;
		}
	}

	static async setWebdocSign(webdocToken: string, actions: WebdocAction[]) {
		return await axios.post(`${process.env.VUE_APP_APP_API_URI}/api/webdoc/sign`, {
			webdocToken: webdocToken,
			actions: actions,
		});
	}

	static async getWebdocFile(webdocToken: string): Promise<Blob> {
		return await axios
			.get(`${process.env.VUE_APP_APP_API_URI}/api/webdoc/download/${webdocToken}`, {
				responseType: 'blob',
			})
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error("📑 Erreur de téléchargement d'un webdoc");
				console.error(error);
				this.toast.error(Vue.prototype.$t('error.downloadWebdoc'));
				throw new Error(error);
			});
	}
}
